@import "../../../../shared/styles/variables.scss";

.root {
  position: relative;
}

.attachmentInput {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: -100;
  width: 1px;
  height: 1px;
}

.attachmentSelector {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50px;
  margin-right: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border: 1px solid #fff;
  cursor: pointer;
  transition: all 0.2s ease;

  .attachmentInput:focus ~ & {
    border: 1px solid $lightblue;
  }
}

.attachmentUploading,
.attachmentSuccess {
  cursor: default;
}

.attachmentProgress {
  position: absolute;
  bottom: -17px;
  height: 3px;
  background: $lightblue;
  opacity: 0;
  transition: all 0.2s ease;

  .attachmentUploading & {
    opacity: 1;
  }
}

.attachmentLabel {
  position: absolute;
  white-space: nowrap;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -5px);
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  border-radius: 3px;
  padding: 2px $spacing-8;
  transition: all 0.2s ease;
  visibility: hidden;
  opacity: 0;

  .attachmentSelector:hover & {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

.attachmentButton {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  & > svg {
    color: $grey-8;
  }
}

.attachmentMeta {
  padding-right: $spacing-12;
  min-width: 50px;
  color: $grey-8;
  display: flex;
  align-items: center;
}

.attachmentName {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachmentSize {
  flex: 0 0 auto;
  margin-left: $spacing-16;
}

.attachmentRemove {
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  margin-right: 3px;
  margin-left: -4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;

  & > svg {
    color: $grey-8;
  }

  &:focus,
  &:hover {
    outline: none;
    background: $grey-3;
  }
}

.vasu {
  height: 48px;
  margin-right: 0;
  background: $grey-1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border: 1px solid $grey-4;
  border-radius: 4px;

  .attachmentLabel {
    display: none;
  }

  .attachmentProgress {
    bottom: 0px;
    border-radius: 0 0 4px 4px;
  }

  .attachmentMeta {
    flex-grow: 1;
  }

  .attachmentRemove {
    margin-right: 7px;
  }
}

@import "../../styles/variables.scss";

.error {
  color: $darkred;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100%;
    overflow-x: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
    }
  }
  &.large {
    font-size: 16px;
    line-height: 24px;
  }
}

.inner {
  display: flex;
  .centered & {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    &:after {
      // Push error message a bit from center to visually center it better
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
}

.icon {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  .centered & {
    margin-right: 0;
    margin-bottom: 4px;
  }
  .large & {
    width: 24px;
    height: 24px;
  }
}

@import "~sass-mq";
@import "../../../shared/styles/variables.scss";

.selectedIconContainer{
  background-color: white;
  border: 1px solid $grey-3;
  margin-top: 10px;
  display: inline-block;
  height: 100px;
   width: 100px
}
.iconStyle{
  position: relative;
  bottom: 70px;
  right: 30px;
  cursor: pointer;
  color: $grey-3;
  z-index: 2
}

.listTitle {
  align-items: center;
  padding: 0 $spacing-12;
  margin-bottom: $spacing-8;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  color: $grey-8;
  @include mq($from: tablet) {
    padding: 0 $spacing-16;
  }
}

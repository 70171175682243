@import "../../../styles/variables";

.container {
  display: flex;
  margin-top: $spacing-32;
  padding: 0 $spacing-16;
  &.left {
    justify-content: flex-start;
  }
  &.right {
    justify-content: flex-end;
  }
  &:first-child {
    margin-top: 0;
  }
}
.inner {
  display: inline-flex;
  flex-direction: column;
  max-width: 90%;
  min-width: 45%;
}

.box {
  position: relative;
  padding: $spacing-16;

  border-radius: $spacing-16;

  .left & {
    background-color: $grey-3;
    &:after {
      content: "";
      position: absolute;
      left: 16px;
      top: 0;
      width: 0;
      height: 0;
      border: 32px solid transparent;
      border-right-color: $grey-3;
      border-left: 0;
      border-top: 0;
      margin-left: -32px;
    }
  }
  .right & {
    background-color: $darkblue;
    &:after {
      content: "";
      position: absolute;
      right: 16px;
      top: 0;
      width: 0;
      height: 0;
      border: 32px solid transparent;
      border-left-color: $darkblue;
      border-right: 0;
      border-top: 0;
      margin-right: -32px;
    }
  }
  &.confirmRemove {
    background-color: $red;
    &:after {
      border-left-color: $red;
      border-right-color: $red;
    }
  }
  &.deleted {
    background-color: $grey-3;
    font-style: italic;
    .text {
      color: $grey-6 !important;
    }
    &:after {
      border-left-color: $grey-3;
      border-right-color: $grey-3;
    }
  }
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: $spacing-16;
}

.text {
  .left & {
    color: $grey-10;
    a {
      color: $red;
    }
  }
  .right & {
    color: #fff;
  }
  .confirmRemove & {
    color: #fff;
  }
  > * + * {
    margin-top: 24px;
  }
  a {
    text-decoration: underline;
  }
  ul,
  ol {
    padding-left: 40px;
  }
  li {
    line-height: 24px;
  }
  li ul,
  li ul {
    margin-left: 24px;
  }
}

.top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-4 $spacing-4;
  > * + * {
    margin-left: $spacing-16;
  }
}
.author {
  font-size: 14px;

  color: $grey-8;
}
.removeButton {
  display: flex;
  align-items: center;
  padding: 0;

  color: $red;
  font-size: 14px;

  cursor: pointer;
}
.removeIcon {
  fill: $red;
  width: 16px;
  height: 16px;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $spacing-4 $spacing-4 0;
}
.date {
  font-size: 14px;
  line-height: 16px;
  color: $grey-8;
}

.confirmDialog {
  text-align: right;
  z-index: 1;
  margin-bottom: $spacing-16;
}
.confirmButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > * + * {
    margin-left: $spacing-16;
  }
}

.confirmTitle {
  margin-bottom: $spacing-8;
  font-weight: 500;

  color: $white;
}

.confirmCancelButton {
  padding: $spacing-4 $spacing-8;
  min-width: 80px;

  border: 2px solid $white;
  border-radius: $spacing-4;

  color: $white;
  font-weight: 500;
  font-size: 14px;
}
.confirmAcceptButton {
  padding: $spacing-4 $spacing-8;
  min-width: 80px;

  border: 2px solid $white;
  border-radius: $spacing-4;

  color: $red;
  font-weight: 500;
  font-size: 14px;

  background-color: $white;
}

.checkMark {
  display: flex;
  align-items: center;
  position: relative;

  font-size: 12px;
  line-height: 16px;
  margin-left: $spacing-8;
  color: $grey-10;
  cursor: help;

  & > svg {
    color: $grey-6;
    width: 24px;
    height: 24px;
    position: relative;
    top: -1px;
  }

  .checkMarkLabel {
    position: absolute;
    right: 100%;
    font-size: 14px;
    background: #fff;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: $spacing-4 $spacing-8;
    margin-right: $spacing-4;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s 0.2s ease-in-out;
    width: 130px;

    .checkMarkContainer:hover & {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
      transform: translateX(-3px);
    }
  }
  .checkCount {
    margin-right: 4px;
    font-weight: 500;
  }

  .widerLabel {
    width: 220px;
  }
}

.attachment {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: 0 0 $spacing-16 $spacing-16;
  padding: $spacing-12 $spacing-16;
  margin: -#{$spacing-16};
  margin-top: $spacing-16;

  .left & {
    background: rgba(0, 0, 0, 0.05);
    color: $darkblue;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .right & {
    background: rgba(255, 255, 255, 0.1);
    color: #fff;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}

.checkMarkSeen {
  & > svg {
    color: $darkgreen;
  }
}
.attachmentIcon {
  flex: 0 0 auto;
  margin-right: $spacing-8;

  .left & {
    color: $darkblue;
  }

  .right & {
    color: #fff;
  }
}

.attachmentName {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.attachmentSize {
  flex: 0 0 auto;
  margin-left: $spacing-16;
}

.readIndicator {
  display: flex;
  align-items: center;
}

.unreadLink {
  margin-left: 16px;

  color: $darkblue;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;

  cursor: pointer;
}

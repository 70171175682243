@import "../../styles/variables";

.search {
  position: relative;
}

.searchIcon {
  position: absolute;
  left: $spacing-8;
  top: 50%;
  margin-top: 1px;
  width: $spacing-24;
  height: $spacing-24;

  fill: $grey-5;

  transform: translateY(-50%);
}

.searchInput {
  width: 100%;
  padding: $spacing-12 $spacing-8;
  padding-left: $spacing-24 + $spacing-8 + $spacing-4;
  padding-right: $spacing-24 + $spacing-8 + $spacing-4;

  border: 1px solid $grey-3;

  font-size: 16px;
  line-height: 24px;

  border-radius: $spacing-4;

  transition: box-shadow 120ms ease, border-color 120ms ease;
  &:focus {
    outline: none;

    border-color: $red;

    box-shadow: 0 0 4px $red;
  }
}

.enter {
  position: absolute;
  padding: 0;
  width: 24px;
  height: 24px;
  right: $spacing-8;
  top: 50%;
  transform: translateY(-50%);

  &:focus {
    outline: none;

    & > svg {
      box-shadow: 0 0 0 2px $lightblue;
      border-radius: 5px;
    }
  }

  & > svg {
    color: $grey-8;

    &:focus {
      outline: none;
    }
  }

  &:hover > svg {
    color: $grey-10;
  }
}

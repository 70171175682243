@import "../../styles/variables";

.container {
  position: relative;
  width: 40px;
  height: 40px;
}

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 26px;
  height: 26px;

  border-radius: 256px;
  box-shadow: 0 0 0 2px #fff;

  color: $white;
  letter-spacing: 0.05em;
  font-size: 12px;
  font-weight: 500;

  background-size: cover;
  background-position: center center;
  background-color: $darkblue;

  &:first-of-type {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.secondAvatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plusRecipients {
  position: absolute;
  bottom: -2px;
  right: -2px;
  background: #fff;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  padding: 0 4px 2px 4px;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.3);
}

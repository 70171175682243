@import "../variables.scss";

.nowrap {
  white-space: nowrap;
}
.weight-400,
.normal {
  font-weight: 400;
}
.weight-500,
.semibold {
  font-weight: 500;
}
.weight-700,
.bold {
  font-weight: 700;
}
.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

.link {
  text-decoration: underline;
  color: $darkblue;

  cursor: pointer;
  &:hover,
  &:focus {
    color: darken($darkblue, 20%);
  }
  &--red {
    color: $darkred;
    &:hover,
    &:focus {
      color: darken($darkred, 20%);
    }
  }
}

.size-small {
  font-size: 14px;
  line-height: 20px;
}
.size-normal {
  font-size: 16px;
  line-height: 24px;
}
.size-medium {
  font-size: 20px;
  line-height: 24px;
}
.size-large {
  font-size: 24px;
  line-height: 32px;
}
.size-huge {
  font-size: 36px;
  line-height: 44px;
}

.color-dark {
  color: $grey-10;
}
.color-light {
  color: $grey-8;
}
.color-red {
  color: $darkred;
}
.color-darkblue {
  color: $darkblue;
}

@import "../../../styles/variables";

.container {
  display: flex;
  flex-shrink: 0;

  background-color: #fff;

  text-decoration: none;

  &.active {
    background-color: $grey-3;
  }
  &:hover {
    background-color: $grey-2;
  }
  &:last-of-type {
    border-bottom: 1px solid $grey-3;
  }
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.inset {
  border-left: 4px solid $darkblue;
}

.left {
  position: relative;
  flex-shrink: 0;
  padding: $spacing-16;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  position: relative;
  flex-shrink: 0;
  padding: $spacing-16;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-16 $spacing-16 $spacing-16 0;
  overflow: hidden;

  border-top: 1px solid $grey-3;
  .container:first-of-type & {
    border-top: none;
  }
}

.top {
  display: flex;
  justify-content: space-between;
}

.topLeft {
  overflow: hidden;
  padding-right: $spacing-8;
}

.topRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}

.title {
  font-size: 16px;
  line-height: 24px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.from {
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 20px;

  color: $grey-8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .unread & {
    color: $grey-10;
    font-weight: 500;
  }
}

.receiverIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 0 4px;

  fill: $grey-7;
}

.date {
  margin-right: $spacing-2;

  color: $grey-8;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
}

.expandCollapse {
  display: inline-block;
  padding: 0px 6px 1px;
  border-radius: 8px;
  background-color: $grey-7;
  color: white;
  font-size: 14px;

  svg {
    position: relative;
    top: 3px;
  }

  &:hover,
  .inset & {
    background-color: $darkblue;
  }
  &:focus {
    outline: none;
  }
}

.pinIcon {
  color: $orange;
  display: inline-block;
}

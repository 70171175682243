@import "../../styles/variables";
@import "~sass-mq";

.timeWrapper {
  display: flex;
  align-items: stretch;
  cursor: pointer;

  &:hover {
    background: $grey-1;
  }
}

.timeLabel {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 16px;
  border-right: 1px solid $grey-3;

  @include mq($from: tablet) {
    padding-left: 24px;
  }
}

.timeInput {
  input[type="time"] {
    text-align: right;
    border: none;
    border-radius: 0;
    min-width: 120px;

    -webkit-appearance: none;
    &::-webkit-input-placeholder {
      line-height: normal !important;
    }
    &:focus {
      outline: none;

      border: none;
      box-shadow: 0 0.25rem 1rem -0.25rem rgba($darkblue, 0.4) inset, 0 0 0 1px $darkblue inset;
    }
  }
}

.timeError {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid $grey-3;

  @include mq($from: tablet) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

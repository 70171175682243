@import "../../../../shared/styles/variables.scss";
@import "~sass-mq";

@value desktopBreakpoint #{map-get($mq-breakpoints, desktop)};

.container {
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  background: #fff;

  @include mq($until: desktop) {
    min-height: calc(100vh - #{$tab-bar-height} - #{$navigation-bar-height});
  }
  &.native {
    @include mq($until: desktop) {
      min-height: 100vh;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding: $spacing-16;

  border-bottom: 1px solid $grey-3;
}

.headerTitle {
  flex-grow: 1;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.headerClose {
  flex-shrink: 0;
}
.headerCloseIcon {
  display: block;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.formRecipient {
  border-bottom: 1px solid $grey-3;
  input:focus {
    box-shadow: none;
  }
}
.formSubject {
  border-bottom: 1px solid $grey-3;
}

.formSubjectInput[type="text"] {
  width: 100%;
  height: 64px;
  padding: $spacing-16;

  border: none;
  border-radius: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @include mq($from: desktop) {
    padding: $spacing-24;
  }
}

.formBody {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px + $spacing-24;
}
textarea.formBodyTextarea {
  width: 100%;
  flex-grow: 1;
  min-height: 100%;
  padding: $spacing-16;

  border: none;
  border-radius: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  @include mq($from: desktop) {
    padding: $spacing-24;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 $spacing-24 $spacing-24 0;
  @include mq($until: desktop) {
    position: fixed;
    bottom: $tab-bar-height;
    width: 100%;
  }
  &.native {
    @include mq($until: desktop) {
      bottom: 0;
    }
  }
}

@import "~sass-mq";
@import "../../../../shared/styles/variables.scss";
.container {
  text-decoration: none;
}
.border{
  border: 1px solid $grey-3;
}
.borderLeft{
  border-left: 1px solid $grey-3;
}
.eventTopBar{
  background: rgb(255, 255, 255);
  border-radius: 0px;
  height: 69px;
  width: 100%;
}
.eventTopBarButton{
  display: inline-block;
  color: rgb(95, 106, 130);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  margin-right: 20px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
}
.editEventBlueBar{
  height: 65px;
  background: rgb(20, 60, 140);
  display: block;
  position: relative;
  top: 69px;
  z-index: 10;
  padding: 15px 50px 0px 50px
}
.editEventBlueBarButton{
  display: inline-block;
  color: rgb(255, 255, 255);;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.8
  }
}
.beigeBackground{
  background: rgb(253, 239, 231);
  border-radius: 0px;
  width: 100%;
  height: 150px;
  z-index: 1
}
.infoContainer{
  background: rgb(250, 251, 253);
  border-radius: 103px 103px 0px 0px;
  width: 100%;
  position: relative;
  z-index: 5;
  top: 68px;
  padding: 30px 50px 0px 50px;
  overflow-x: hidden;

}
.importantArrow{
  background-color: rgb(253, 239, 231);
  display: inline-block;
  color: rgb(255, 88, 71);
  font-size: 14px;
  letter-spacing: 0px;
  padding: 1px 10px 1px 5px;
  border-radius: 10px;
  color: rgb(255, 88, 71);
  margin-left: 30px;
}
.eventName{
  color: rgb(6, 21, 64);
  font-size: 24px;
  font-weight: 500;
  height: 24px;
  letter-spacing: 0px;
  width: 112px;
  margin-top: 15px;
  margin-left: 30px;
}
.topInfoContainer{
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 30px;
  border-left: 1px solid rgb(235, 238, 245);
  border-top: 1px solid rgb(235, 238, 245);
  border-right: 1px solid rgb(235, 238, 245);
  border-radius: 5px;
}
.grayText{
  color: rgb(134, 144, 164);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 0px 10px 15px;
}
.blackText{
  color: rgb(0, 0, 0);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 0px 10px 0px;
}
.descriptionText{
  color: rgb(95, 106, 130);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 25px 10px 0px;
}
.topInfoItem{
  flex: 1;
  display: flex;
  border-bottom: 1px solid rgb(235, 238, 245);;
}
.infoItem{
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid rgb(235, 238, 245);
}
.groupedContainer{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgb(235, 238, 245);
}
.infoItemGrouped{
  flex: 1;
  display: flex;
  flex-direction: row;
}
.infoItemGroupedBorder{
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(235, 238, 245);;
}




.editorWrapper {
  flex: 1 1 auto;
  border-left: 1px solid #ebeef5;
  // targets editor root
  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 250px;
    border: none;
    border-radius: 0;
    font-family: inherit;

    // targets toolbar
    & > div:nth-of-type(1) {
      margin: 0;
      padding: 14px 14px 0 14px;
      border: none;
      flex: 0 0 auto;
      display: flex;
      flex-wrap: wrap;

      @include mq($from: desktop) {
        padding: 22px 22px 0 22px;
      }

      // targets button groups
      & > div {
        margin-right: $spacing-16;

        &:last-of-type {
          margin-right: 0;
          margin-left: auto;
        }

        // targets buttons
        & > div > button {
          border: 1px solid $grey-3;
          border-radius: 50%;
          margin-right: $spacing-4;
        }
      }
    }

    // targets textarea
    & > div:nth-of-type(2) {
      margin: 0;
      padding: 0 5px;
      flex: 1 1 auto;

      @include mq($from: desktop) {
        padding: 0 13px;
      }

      & > div {
        height: 100%;
      }
    }
  }
}

@import "../variables.scss";

input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
.DayPickerInput > input,
select,
textarea {
  padding: 8px 12px;
  width: 100%;
  height: 48px;

  border: 1px solid $grey-3;
  border-radius: 4px;

  line-height: 24px;

  background: white;
  transition: box-shadow 120ms ease, border-color 120ms ease;
  &:focus {
    outline: none;

    border-color: $red;
  }
}

select {
}

textarea {
  height: auto;
  min-height: 48px;
}

button,
input[type="submit"] {
  padding: 8px;
  // border-radius: 4px;
  // border: 1px solid;
}

@import "../../styles/variables";

.reservations {
  margin-top: $spacing-16;
}

.reservation {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $spacing-16 0;
  border-bottom: 1px solid rgba($darkgreen, 0.5);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.cancelInfo {
  font-size: 14px;
  line-height: 1.5;
  color: $darkgreen;
  margin-bottom: $spacing-8;
}

.time {
  font-weight: 500;
}

.name {
  padding-top: $spacing-8;
  font-size: 18px;
}

.moreInfo {
  font-size: 14px;
}

.cancelButton {
  position: relative;
}

.cancelPrompt {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 2;
  background: $white;
  width: 240px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.15);
  padding: $spacing-8;
  animation: cancelPrompt 0.2s ease-in-out backwards;
}

.cancelPromptButtons {
  display: flex;
  margin: -4px;
  padding-top: $spacing-8;

  & > * {
    margin: $spacing-4;
  }
}

.cancelPromptButton {
  position: relative;
  flex: 1 1 0%;
}

.empty {
  margin-top: $spacing-8;
}

.pastReservationsToggle {
  margin: $spacing-16 0;
}

@keyframes cancelPrompt {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@import "~sass-mq";
@import "../../../styles/variables.scss";

.starIcon{
  background-color: #ff6524;
  color: white;
  position: relative;
  top: 5px;
  left: 10px;
  border-radius: 25px
}
.bellRingIcon{
  margin-left: 10px;
  margin-right: 10px;
  top: 5px;
  position: relative;
  color: #adadad
}

.reminderText{
  display: inline-block;
  color: #adadad
}
.border {
  border: 1px solid $grey-3;

}

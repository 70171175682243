// URLs relative to app/javascript/packs
$maaxRegularEot: "../../assets/fonts/Maax-Regular-205TF.eot";
$maaxRegularWoff2: "../../assets/fonts/Maax-Regular-205TF.woff2";
$maaxRegularWoff: "../../assets/fonts/Maax-Regular-205TF.woff";
$maaxRegularTtf: "../../assets/fonts/Maax-Regular-205TF.ttf";

$maaxItalicEot: "../../assets/fonts/Maax-Italic-205TF.eot";
$maaxItalicWoff2: "../../assets/fonts/Maax-Italic-205TF.woff2";
$maaxItalicWoff: "../../assets/fonts/Maax-Italic-205TF.woff";
$maaxItalicTtf: "../../assets/fonts/Maax-Italic-205TF.ttf";

$maaxMediumEot: "../../assets/fonts/Maax-Medium-205TF.eot";
$maaxMediumWoff2: "../../assets/fonts/Maax-Medium-205TF.woff2";
$maaxMediumWoff: "../../assets/fonts/Maax-Medium-205TF.woff";
$maaxMediumTtf: "../../assets/fonts/Maax-Medium-205TF.ttf";

$maaxBoldEot: "../../assets/fonts/Maax-Bold-205TF.eot";
$maaxBoldWoff2: "../../assets/fonts/Maax-Bold-205TF.woff2";
$maaxBoldWoff: "../../assets/fonts/Maax-Bold-205TF.woff";
$maaxBoldTtf: "../../assets/fonts/Maax-Bold-205TF.ttf";

@font-face {
  font-family: "Maax";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url("#{$maaxRegularEot}");
  src:
    url("#{$maaxRegularEot}?#iefix") format("embedded-opentype"),
    url("#{$maaxRegularWoff2}") format("woff2"),
    url("#{$maaxRegularWoff}") format("woff"),
    url("#{$maaxRegularTtf}") format("truetype");
}

@font-face {
  font-family: "Maax";
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: url("#{$maaxItalicEot}");
  src:
    url("#{$maaxItalicEot}?#iefix") format("embedded-opentype"),
    url("#{$maaxItalicWoff2}") format("woff2"),
    url("#{$maaxItalicWoff}") format("woff"),
    url("#{$maaxItalicTtf}") format("truetype");
}

@font-face {
  font-family: "Maax";
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: url("#{$maaxMediumEot}");
  src:
    url("#{$maaxMediumEot}?#iefix") format("embedded-opentype"),
    url("#{$maaxMediumWoff2}") format("woff2"),
    url("#{$maaxMediumWoff}") format("woff"),
    url("#{$maaxMediumTtf}") format("truetype");
}

@font-face {
  font-family: "Maax";
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: url("#{$maaxBoldEot}");
  src:
    url("#{$maaxBoldEot}?#iefix") format("embedded-opentype"),
    url("#{$maaxBoldWoff2}") format("woff2"),
    url("#{$maaxBoldWoff}") format("woff"),
    url("#{$maaxBoldTtf}") format("truetype");
}

@import "../../styles/variables.scss";
@import "~sass-mq";

// Wrapper
.list {
  margin-top: $spacing-32;
  &.noMargin {
    margin-top: 0;
  }
}

.listTitleContainer {
  display: flex;
  justify-content: space-between;
}

// Title above actual list
.listTitle {
  display: flex;
  align-items: center;
  padding: 0 $spacing-12;
  margin-bottom: $spacing-8;

  font-size: 14px;
  font-weight: 500;
  color: $grey-8;
  @include mq($from: tablet) {
    padding: 0 $spacing-16;
  }
}

// Icon next to title
.listIcon {
  margin-right: $spacing-4;
  width: 20px;
  height: 20px;

  fill: $grey-7;
}

// Container for all list items
.listChildContainer {
  border-top: 1px solid $grey-3;
  border-bottom: 1px solid $grey-3;

  background-color: $white;
  @include mq($from: tablet) {
    border-left: 1px solid $grey-3;
    border-right: 1px solid $grey-3;

    border-radius: $spacing-8;

    .noRadius & {
      border-radius: 0;
    }
  }

  .greenBorder > & {
    border-color: rgba($darkgreen, 0.8);
    background: lighten($mintgreen, 10%);

    @include mq($from: tablet) {
      box-shadow: inset 0px 0px 0px 3px rgba($darkgreen, 0.8);
      border-color: transparent;
    }
  }
}

// Base styles for all items inside list
.item {
  background-color: $white;

  @include mq($from: tablet) {
    &:first-child {
      border-top-left-radius: $spacing-8;
      border-top-right-radius: $spacing-8;
    }
    &:last-child {
      border-bottom-left-radius: $spacing-8;
      border-bottom-right-radius: $spacing-8;
    }
  }
}

// Basic list items with left middle and right sections
.listItem {
  composes: item;
  display: flex;
  align-items: stretch;
  &.link {
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background-color: $grey-1;
    }
  }
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding-left: $spacing-8;
  padding-right: $spacing-8;
  @include mq($from: tablet) {
    padding-left: $spacing-12;
    padding-right: $spacing-12;
  }
}
.middle {
  flex-grow: 1;
  align-self: center;
  padding-top: $spacing-12;
  padding-bottom: $spacing-12;

  border-top: 1px solid $grey-3;
  .listItem:first-child & {
    border-top: none;
  }
  @include mq($from: tablet) {
    padding-top: $spacing-16;
    padding-bottom: $spacing-16;
  }
}
.right {
  display: flex;
  flex-shrink: 0;
  padding: $spacing-12;
  padding-right: $spacing-16;

  border-top: 1px solid $grey-3;
  .listItem:first-child & {
    border-top: none;
  }
  @include mq($from: tablet) {
    padding: $spacing-16;
    padding-right: $spacing-24;
  }
}

// Action list item with only text
.listAction {
  composes: item;

  display: flex;
  align-items: center;
  width: 100%;
  padding: $spacing-12 $spacing-16;
  padding-left: $spacing-12;

  border-top: 1px solid $grey-3;

  text-align: left;
  color: $darkblue;
  font-weight: 500;
  text-decoration: none;
  &:first-child {
    border-top: none;
  }
  @include mq($from: tablet) {
    padding: $spacing-16 $spacing-24;
    padding-left: $spacing-16;
  }
  &:hover {
    background-color: $grey-1;
  }
  &:focus {
    outline: none;
  }
  &.red {
    color: $red;
    &:hover {
      background-color: lighten($red, 34%);
    }
  }
}

.listContent {
  composes: item;
  padding: $spacing-16;
  @include mq($from: tablet) {
    padding: $spacing-24;
  }
  border-top: 1px solid $grey-3;
  &:first-child {
    border-top: none;
  }
  &.empty {
    padding: 0;
    @include mq($from: tablet) {
      padding: 0;
    }
  }
}

.listActionIcon {
  width: 24px;
  height: 24px;
  margin-right: $spacing-4;
}

// Buttons
.button {
  display: flex;
  flex-shrink: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.buttonIcon {
  display: block;
  fill: $red;
  width: 24px;
  height: 24px;
  .button:hover &,
  .button:focus & {
    fill: $darkred;
  }
}

// Link icon
.listItemLinkIcon {
  align-self: center;
  width: 32px;
  height: 32px;

  fill: $darkblue;
}

// Form inside list
.form {
  composes: item;

  position: relative;

  border-top: 1px solid $grey-3;

  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.075);
  &:first-child {
    border-top: none;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 1px;

    background-color: $grey-3;
  }
  &:last-child:after {
    display: none;
  }
}

.formTitle {
  margin-bottom: $spacing-8;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  .empty & {
    margin-bottom: 0;
    padding: $spacing-16 $spacing-16 $spacing-12;
    @include mq($from: tablet) {
      padding: $spacing-16 $spacing-24 $spacing-12;
    }
  }
}

.formInputs {
  padding: $spacing-16 $spacing-16 $spacing-12;
  @include mq($from: tablet) {
    padding: $spacing-16 $spacing-24 $spacing-12;
  }
  .empty & {
    padding: 0;
    @include mq($from: tablet) {
      padding: 0;
    }
  }
}

.formActions {
  display: flex;

  border-top: 1px solid $grey-3;
  > * + * {
    border-left: 1px solid $grey-3;
  }
}

.formActionsCancel {
  flex-grow: 1;
  padding: $spacing-16;

  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  font-weight: 700;
  color: $grey-8;
  &:hover {
    background-color: $grey-1;
  }
  &:focus {
    outline: none;
  }
  .form:last-child & {
    border-bottom-left-radius: $spacing-8;
  }
}
.formActionsSubmit {
  composes: formActionsCancel;

  color: $darkblue;
  .form:last-child & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: $spacing-8;
  }
}

// Checkbox list item
.listCheckbox {
  composes: item;

  position: relative;
  display: flex;
  align-items: center;
  

  border-top: 1px solid $grey-3;

  cursor: pointer;
  &:first-child {
    border-top: none;
  }
  &:hover:not(.disabled) {
    background-color: $grey-1;
  }
  &.disabled {
    cursor: not-allowed;
  }
}

.listCheckboxInput {
  position: absolute;
  opacity: 0 !important;
  width: 100%;
  height: 100%;
}

.listCheckboxContent {
  width: 100%;
  display: flex;
  align-items: center;
  padding: $spacing-12 $spacing-16;
  @include mq($from: tablet) {
    padding: $spacing-16 $spacing-24;
  }
  input:checked + & {
    background: rgba($darkgreen, 0.03);

    .warning & {
      background: rgba($darkred, 0.2);
    }
  }
}

.listCheckboxLabel {
  flex-grow: 1;
  input:checked + .listCheckboxContent & {
    color: $darkgreen;
    font-weight: 500;

    .warning & {
      color: $darkred;
    }
  }

  .disabled & {
    opacity: 0.5;
  }

  .flex & {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.listCheckboxIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 8px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid $grey-4;
  box-shadow: 0 0 8px $grey-3 inset;
  input:checked + .listCheckboxContent & {
    box-shadow: 0 0 8px $darkgreen inset;
    background: $darkgreen;
  }

  .disabled & {
    opacity: 0.5;
  }
}
.listCheckboxIcon {
  fill: $white;
  width: 20px;
  height: 20px;
  opacity: 0;

  input:checked + .listCheckboxContent .listCheckboxIndicator & {
    opacity: 1;
  }
}

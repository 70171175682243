@import "../../styles/variables";
@import "~sass-mq";

.root {
  margin: $spacing-24 0;

  @include mq($until: tablet) {
    padding: 0 $spacing-24;
  }
}

.nameAndAvatar {
  display: flex;
  align-items: center;
}

.childName {
  margin-left: $spacing-8;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
}

.meta {
  margin-top: $spacing-24;
  display: flex;
  flex-direction: column;
}

.metaItem {
  display: flex;
}

.metaLabelShort {
  width: 110px;
}

.metaLabelLong {
  width: 150px;
}

.metaValue {
  font-weight: 500;
}

//
// Automatical margins between child elements

.child-margins-x-2 > * + * {
  margin-left: 2px;
}
.child-margins-x-4 > * + * {
  margin-left: 4px;
}
.child-margins-x-8 > * + * {
  margin-left: 8px;
}
.child-margins-x-12 > * + * {
  margin-left: 12px;
}
.child-margins-x-16 > * + * {
  margin-left: 16px;
}
.child-margins-x-20 > * + * {
  margin-left: 20px;
}
.child-margins-x-24 > * + * {
  margin-left: 24px;
}
.child-margins-x-32 > * + * {
  margin-left: 32px;
}
.child-margins-x-48 > * + * {
  margin-left: 48px;
}
.child-margins-x-64 > * + * {
  margin-left: 64px;
}
.child-margins-x-128 > * + * {
  margin-left: 128px;
}

.child-margins-y-2 > * + * {
  margin-top: 2px;
}
.child-margins-y-4 > * + * {
  margin-top: 4px;
}
.child-margins-y-8 > * + * {
  margin-top: 8px;
}
.child-margins-y-12 > * + * {
  margin-top: 12px;
}
.child-margins-y-16 > * + * {
  margin-top: 16px;
}
.child-margins-y-20 > * + * {
  margin-top: 20px;
}
.child-margins-y-24 > * + * {
  margin-top: 24px;
}
.child-margins-y-32 > * + * {
  margin-top: 32px;
}
.child-margins-y-48 > * + * {
  margin-top: 48px;
}
.child-margins-y-64 > * + * {
  margin-top: 64px;
}
.child-margins-x-128 > * + * {
  margin-top: 128px;
}

.child-margins-x-hairline > * + * {
  margin-left: 1px;
}
.child-margins-y-hairline > * + * {
  margin-top: 1px;
}

//
//Paddings

.padding-hairline {
  padding: 1px;
}

.padding-2 {
  padding: 2px;
}
.padding-4 {
  padding: 4px;
}
.padding-8 {
  padding: 8px;
}
.padding-12 {
  padding: 12px;
}
.padding-16 {
  padding: 16px;
}
.padding-20 {
  padding: 20px;
}
.padding-24 {
  padding: 24px;
}
.padding-32 {
  padding: 32px;
}
.padding-48 {
  padding: 48px;
}
.padding-64 {
  padding: 64px;
}
.padding-128 {
  padding: 128px;
}

.padding-top {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.padding-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.padding-bottom {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
.padding-left {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.padding-x {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-y {
  padding-left: 0;
  padding-right: 0;
}

.padding-top-0 {
  padding-top: 0;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-0 {
  padding: 0;
}

//
// Margins

.margin-auto {
  margin: auto;
}
.margin-auto-x {
  margin-left: auto;
  margin-right: auto;
}
.margin-auto-y {
  margin-top: auto;
  margin-bottom: auto;
}

.margin-hairline {
  margin: 1px;
}

.margin-2 {
  margin: 2px;
}
.margin-4 {
  margin: 4px;
}
.margin-8 {
  margin: 8px;
}
.margin-12 {
  margin: 12px;
}
.margin-16 {
  margin: 16px;
}
.margin-20 {
  margin: 20px;
}
.margin-24 {
  margin: 24px;
}
.margin-32 {
  margin: 32px;
}
.margin-48 {
  margin: 48px;
}
.margin-64 {
  margin: 64px;
}
.margin-128 {
  margin: 128px;
}

.negative-margin-2 {
  margin: -2px;
}
.negative-margin-4 {
  margin: -4px;
}
.negative-margin-8 {
  margin: -8px;
}
.negative-margin-12 {
  margin: -12px;
}
.negative-margin-16 {
  margin: -16px;
}
.negative-margin-20 {
  margin: -20px;
}
.negative-margin-24 {
  margin: -24px;
}
.negative-margin-32 {
  margin: -32px;
}
.negative-margin-48 {
  margin: -48px;
}
.negative-margin-64 {
  margin: -64px;
}
.negative-margin-128 {
  margin: -128px;
}

.margin-top {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.margin-right {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.margin-bottom {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}
.margin-left {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}

.margin-x {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-y {
  margin-left: 0;
  margin-right: 0;
}

.margin-top-0 {
  margin-top: 0;
}
.margin-right-0 {
  margin-right: 0;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-left-0 {
  margin-left: 0;
}

.height-100 {
  height: 100%;
}

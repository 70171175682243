@import "../../styles/variables.scss";
@import "~sass-mq";

@value desktopBreakpoint #{map-get($mq-breakpoints, desktop)};


.backgroundImage{
  width: auto;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;

  @include mq($until: desktop) {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box
  }
}

.icon{
  width: auto;
  top: calc(50%);
  left: 50%;
  height: 80px;
  width: 80px;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  opacity: 0.7
}
.controls{
  background-color: transparent;
  position: absolute;
  top: 10px;
  width: 100%;
}
.thumbnailUrl{
  min-width: 100vw;
}
.photoInfo{
  display: inline-block;
  color: white;
  float: left;
  margin-right: 15px;
  margin-left: 10px
}
.downloadButtonContainer{
  margin-bottom: 5px;
  float: left;
}
.closeIcon{
  position: relative;
  color: white;
  top: 7px;
  margin-right: 15px;
  cursor: pointer;
  display: inline-block;
  float: right;
}
.staffContainer{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 100vh;
  @include mq($until: desktop) {
    height: 100vh;
    width: 90%;
    display: table-cell;
    vertical-align: middle;

  }
}
.staffVideoContainer{

    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 100vh;
}
.descriptionContainer{
  position: absolute;
  width: 100%;
  top: 80vh;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.454);
}


.closeVideoIcon{
  position: absolute;
  color: white;
  height: 25;
  width: 25;
  top: 10px;
  right: 10px;
  cursor: pointer
}

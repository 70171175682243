@import "../../../../shared/styles/variables.scss";

.root {
  display: flex;
  align-items: center;
  padding: $spacing-16;
  background: $darkgreen;
  color: #fff;

  animation-name: slideIn;
  animation-duration: 0.5s;
  animation-fill-mode: backwards;
  animation-timing-function: ease;

  & > svg {
    width: 40px;
    height: 40px;
  }
}

.hidden {
  display: none;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;

  &:hover {
    background: lighten($darkgreen, 7%);
  }

  &:focus {
    outline: none;
    border-color: lighten($darkgreen, 7%);
  }

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.text {
  flex-grow: 1;
  padding: 0 $spacing-16;
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: none;
  }
}

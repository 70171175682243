@import "../../styles/variables";

.avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  border-radius: 256px;

  color: $white;
  letter-spacing: 0.05em;
  font-size: 16px;
  font-weight: 500;

  background-size: cover;
  background-position: center center;
  background-color: $darkblue;
  &.white {
    color: $darkblue;
    background-color: $white;
  }
  &.onlyIcon {
    background-color: transparent;
  }
}

.icon {
  width: 32px;
  height: 32px;

  fill: $darkblue;
  .white & {
    fill: $darkblue;
  }
}

.medium {
  width: 64px;
  height: 64px;
}

.large {
  width: 256px;
  height: 256px;
}

.square {
  border-radius: 16px;
}

@import "../../../styles/variables";

.messageSearch {
  position: relative;
  background: $grey-2;
  z-index: 2;
}

.searchResults {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: 0px;
  overflow: hidden;
  padding-bottom: $spacing-24;
}

.searchBox {
  background: #fff;
  padding: $spacing-12;
  display: flex;
}

.showSearchResults {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  & .searchResults {
    flex: 1 1 auto;
    position: relative;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    height: auto;
    overflow: auto;
  }

  & .searchBox {
    flex: 0 0 auto;
    padding-bottom: 0;
  }
}

.categoryResults {
  padding: $spacing-12;
}

.categoryTitle {
  position: sticky;
  top: 0;
  z-index: 2;
  padding: $spacing-12 0;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid $grey-3;

  & > span {
    font-weight: 500;
    margin-right: $spacing-8;
  }
}

.noResults {
  color: $grey-8;
  font-weight: 500;
  text-align: center;
}

@import "../../styles/variables";
@import "~sass-mq";

.buttonBaseStyles {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  min-width: 96px;

  border: 1px solid transparent;

  border-radius: $spacing-8;

  white-space: nowrap;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;

  transition: background 0.2s ease-in-out;
  &:focus {
    outline: none;
  }
  &.hasIcon {
    padding-left: $spacing-12;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
    &.small {
      padding-left: 6px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    &.round {
      padding-left: $spacing-16;
      padding-right: $spacing-24;
    }
  }
  &.small {
    min-width: 0;
    padding: 0 $spacing-8;
    height: 32px;

    border-radius: $spacing-4;

    text-transform: none;
    font-weight: 500;
    letter-spacing: 0;
    font-size: 14px;
    line-height: 20px;

    &.round {
      padding: 0 $spacing-12;
    }
  }
  &.narrow {
    padding: 0 $spacing-12 !important;
    text-transform: none;
    font-size: 14px;
  }
  &.large {
    min-width: 240px;
    height: 48px;

    font-size: 16px;
    @include mq($until: tablet) {
      font-size: 14px;
    }
  }
  &.round {
    border-radius: 256px;
    padding-left: $spacing-24;
    padding-right: $spacing-24;
  }
  &.fullWidth {
    width: 100%;
  }
  &.noMinWidth {
    min-width: 0;
  }
  &.circle {
    border-radius: 256px;
    padding: 0;
    width: 36px;
    height: 36px;
    min-width: 36px;
  }
}

.button {
  composes: buttonBaseStyles;

  border-color: $red;

  color: $white;

  background-color: $red;
  &:hover {
    background: $darkred;
  }
  &:focus {
    background: $darkred;

    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25) inset;
  }

  &.hasIcon {
    svg {
      fill: $white;
    }
  }
  &.fab {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  }
  &.red:not(:disabled) {
    border-color: $red;

    background-color: $red;
    &:hover {
      background: $darkred;
    }
    &:focus {
      background: $darkred;
    }
  }
  &.green:not(:disabled) {
    border-color: $darkgreen;

    background-color: $darkgreen;
    &:hover {
      background: darken($darkgreen, 6%);
    }
    &:focus {
      background: $darkgreen;
    }
  }
  &.blue:not(:disabled) {
    border-color: $darkblue;

    background-color: $darkblue;
    &:hover {
      background: darken($darkblue, 6%);
    }
    &:focus {
      background: $darkblue;
    }
  }
  &.white:not(:disabled) {
    border: 1px solid rgb(235, 238, 245);

    background-color: white;
    &:hover {
      background: darken(white, 6%);
    }
    &:focus {
      background: white;
    }
  }
  &:disabled {
    opacity: 1;

    border-color: $grey-3;

    color: $grey-6;

    background-color: $grey-3;
    &:hover {
      background-color: $grey-3;
    }
  }
}

.outlineButton {
  composes: buttonBaseStyles;

  border-color: $grey-5;

  color: $grey-8;

  background-color: transparent;

  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  &.hasIcon {
    svg {
      fill: $grey-7;
    }
  }
  &:hover {
    border-color: $red;

    color: $red;
    &.hasIcon {
      svg {
        fill: $red;
      }
    }
  }
  &:focus {
    border-color: $red;
    box-shadow: 0 0 0 1px $red inset;

    color: $red;
    &.hasIcon {
      svg {
        fill: $red;
      }
    }
  }
}

.outlineRed {
  border-color: $red;
  color: $red;

  &.hasIcon {
    svg {
      fill: $red;
    }
  }
}

.outlineBlue {
  border-color: $darkblue;
  color: $darkblue;

  &.hasIcon {
    svg {
      fill: $darkblue;
    }
  }
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;

  .hasIcon & {
    display: flex;
    align-items: center;

  }
  .whiteText &{
    color: white;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
  }
  .greenText & {
    color: rgb(0, 154, 118);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
  }
  .redText & {
    color: rgb(255, 88, 71);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
  }
  .grayText & {
    color: gray;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: center;
  }
}

.actionBarStyle {
  padding-top: 12px;
  padding-bottom: 12px;
  height: auto;
  border-radius: 0;
}

@include mq($from: tablet) {
  .actionBarStyle:first-child {
    border-bottom-left-radius: 8px;
  }
  .actionBarStyle:last-child {
    border-bottom-right-radius: 8px;
  }
}

@import "../../styles/variables";

.root {
  padding: $spacing-12;
  background: #fff;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-12 $spacing-16;
  border: 1px solid $grey-5;
  border-radius: 50px;
}

.value {
  font-weight: 500;
}

.close {
  padding: 0;
  width: 24px;
  height: 24px;

  & > svg {
    color: $grey-8;
  }

  &:hover > svg {
    color: $grey-10;
  }
}

@import "../../styles/variables";
@import "~sass-mq";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav {
  background: $white;
  min-height: 64px;
  border-bottom: 1px solid $grey-3;
  z-index: 10;
}

.navInner {
  height: 100%;
  padding: $spacing-12;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.timeWarning {
  padding: $spacing-12;
  display: flex;
  background: $yellow;
  border-bottom: 1px solid $grey-3;

  & span {
    font-weight: 500;
  }

  & > svg {
    margin-right: $spacing-8;
  }
}

.content {
  flex-grow: 1;
  overflow: auto;
}

.header {
  background: $white;
  padding: $spacing-24;
}

.backLink {
  display: flex;
  align-items: center;
  color: $darkblue;
  text-decoration: none;
  font-weight: 500;

  & > svg {
    width: 24px;
    height: 24px;
  }
}

.backLinkContent {
  position: relative;
  width: 1px;
  height: 40px;

  & > span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: top 0.2s ease-in;

    .backLinkScrolled & {
      top: calc(50% - 60px);
    }
  }
}

.childLastName {
  @include mq($until: desktop) {
    display: none;
  }
}

.printLink {
  text-decoration: none;
}

.success {
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 80px;
  right: 16px;
  background: $darkgreen;
  color: $white;
  font-weight: 500;
  padding: $spacing-12;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px rgba($darkgreen, 0.4);
  animation: fadeIn 0.2s ease-in-out backwards;
  transition: all 0.2s ease-in-out;

  @include mq($until: desktop) {
    top: 140px;
  }

  & > svg {
    width: 16px;
    height: 16px;
    margin-right: $spacing-8;
  }
}

.publishSuccess {
  composes: success;
  background: $red;
  box-shadow: 0px 3px 5px 0px rgba($red, 0.4);
  z-index: 101;
}

.error {
  composes: success;
  background: $darkred;
  box-shadow: 0px 3px 5px 0px rgba($darkred, 0.4);
  z-index: 101;
}

.fadingOut {
  opacity: 0;
}

.publishTooltip {
  position: fixed;
  top: 60px;
  right: 16px;
  z-index: 9;
  background: $grey-1;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.15);
  padding: $spacing-8;
  animation: fadeIn 0.2s ease-in-out backwards;
  display: none;

  .publishButton:hover & {
    display: block;
  }
}

.userPrompt {
  position: fixed;
  top: 80px;
  right: 16px;
  width: 350px;
  z-index: 9;
  background: $white;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.15);
  padding: $spacing-8;
  animation: fadeIn 0.2s ease-in-out backwards;
}

.userPromptButtons {
  display: flex;
  margin: -4px;
  padding-top: $spacing-8;

  & > * {
    margin: $spacing-4;
  }
}

.userPromptButton {
  position: relative;
  flex: 1 1 0%;
}

.childTitle {
  display: flex;
  align-items: center;
  font-size: 36px;
  line-height: 44px;
  font-weight: 500;

  & > span {
    margin-left: $spacing-8;
  }

  @include mq($until: desktop) {
    font-size: 24px;
    line-height: 32px;
  }
}

.subtitle {
  font-size: 28px;
  line-height: 36px;

  @include mq($until: desktop) {
    font-size: 20px;
    line-height: 28px;
  }
}

.childMeta {
  position: absolute;
  top: calc(50% + 60px);
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  margin-left: $spacing-4;
  transition: top 0.2s ease-in;

  & > span {
    white-space: nowrap;
  }

  .backLinkScrolled & {
    top: 50%;
  }
}

.childName {
  margin-left: $spacing-8;
  font-weight: 500;
}

.headerButtons {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: $spacing-8;
}

.timerLabel {
  color: $grey-8;
  font-size: 14px;
  line-height: 1;
}

.timerTimer {
  font-weight: 500;
}

.timeRed {
  color: $darkred;
}

.headerButton {
  margin-left: $spacing-8;
}

.metaContainer {
  display: flex;
  margin: $spacing-24 -24px 0 -24px;

  @include mq($until: desktop) {
    flex-direction: column;
    font-size: 14px;
    line-height: 1.5;
  }
}

.meta {
  margin: 0 $spacing-24;
}

.metaItem {
  display: flex;
}

.metaLabelShort {
  width: 110px;
}

.metaLabelLong {
  width: 170px;
}

.metaValue {
  font-weight: 500;
}

.metaDividerContainer {
  display: flex;
  align-items: center;
}

.metaDivider {
  width: 1px;
  height: 50px;
  background: $grey-4;

  @include mq($until: desktop) {
    width: 70px;
    height: 1px;
    margin: $spacing-12 $spacing-24;
  }
}

.document {
  position: relative;
  width: 100%;
}

.documentInner {
  @include mq($from: desktop, $until: notebook) {
    min-width: 848px;
    overflow: auto;
  }
}

.sectionTitle {
  position: relative;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: $spacing-8;

  @include mq($until: desktop) {
    font-size: 18px;
    line-height: 26px;
  }
}

.section {
  position: relative;
  padding: $spacing-24;
  border-top: 1px solid $grey-3;

  @include mq($from: superwide) {
    padding-right: calc(100% - 1300px);
  }
}

.sectionFlex {
  display: flex;
}

.questionTitle {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: $spacing-8;
  align-self: flex-end;
}

.questionDescription {
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
  margin: $spacing-16 0;
  align-self: flex-end;
}

.largeQuestionTitle {
  font-weight: 500;
  margin: $spacing-24 0 $spacing-8 0;
}

.sectionIndicator {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 4px;
  height: calc(100% - 48px);
  border-radius: 0 4px 4px 0;
}

.sectionIndicatorRed {
  composes: sectionIndicator;
  background: $red;
}

.sectionIndicatorOrange {
  composes: sectionIndicator;
  background: $orange;
}

.sectionIndicatorYellow {
  composes: sectionIndicator;
  background: $yellow;
}

.sectionIndicatorGreen {
  composes: sectionIndicator;
  background: $darkgreen;
}

.sectionIndicatorMint {
  composes: sectionIndicator;
  background: $mintgreen;
}

.sectionIndicatorLightblue {
  composes: sectionIndicator;
  background: $lightblue;
}

.sectionIndicatorDarkblue {
  composes: sectionIndicator;
  background: $darkblue;
}

.sectionIndicatorPurple {
  composes: sectionIndicator;
  background: #a347ff;
}

.sectionIndicatorPink {
  composes: sectionIndicator;
  background: #f06adc;
}

.sectionIndicatorLightred {
  composes: sectionIndicator;
  background: $lightred;
}

.questionTitleWide {
  composes: questionTitle;
  width: 400px;
}

.questionTitleNarrow {
  composes: questionTitle;
  width: 300px;
  margin-left: $spacing-24;
}

.editorFlexContainer {
  display: flex;
  margin: -8px;

  & > div {
    flex: 1 1 0%;
    margin: $spacing-8;
  }

  @include mq($until: desktop) {
    flex-direction: column;
  }
}

.editorFlexContainerNarrow {
  composes: editorFlexContainer;
  width: 60%;
  min-width: 400px;

  @include mq($until: desktop) {
    width: auto;
    min-width: auto;
  }
}

.tripleTextInputs {
  width: 400px;

  & > .tripleTextInput {
    position: relative;
    z-index: 1;
    resize: none;
    min-height: auto;

    &:focus {
      z-index: 2;
    }
    &:nth-child(1) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      margin-top: -1px;
      border-radius: 0;
    }
    &:nth-child(3) {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.photoSelector {
  background: $white;
  border-radius: 4px;
  border: 1px solid $grey-3;
  margin-left: $spacing-24;
  width: 300px;
}

.matrixContainer {
  @include mq($until: desktop) {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 9;
      width: 16px;
      top: 1px;
      left: 1px;
      bottom: 1px;
      background: linear-gradient(to right, $grey-1, rgba($grey-1, 0));
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 9;
      width: 16px;
      top: 1px;
      right: 1px;
      bottom: 1px;
      background: linear-gradient(to left, $grey-1, rgba($grey-1, 0));
    }
  }
}

.matrix {
  @include mq($from: desktop, $until: notebook) {
    min-width: 800px;
  }

  @include mq($until: desktop) {
    max-width: 100%;
    overflow: auto;
    margin-right: -24px;
    border: 1px solid $grey-3;
  }
}

.row {
  display: flex;
  padding: $spacing-16 0;
  border-bottom: 1px solid $grey-4;

  &:last-of-type {
    border-bottom: none;
  }

  @include mq($until: desktop) {
    border-bottom: none;
    padding: $spacing-8 0;

    &:last-of-type {
      padding-bottom: $spacing-16;
    }
  }
}

.firstRow {
  composes: row;
  position: sticky;
  top: 0px;
  background: $grey-1;
  z-index: 9;
  padding: $spacing-4 0;
  margin-top: $spacing-12;
}

.column {
  flex: 1 1 30%;
  display: flex;
  margin-right: $spacing-16;
  min-width: 186px;

  @include mq($until: desktop) {
    min-width: 250px;
  }

  &:last-of-type {
    @include mq($from: notebook) {
      margin-right: 0;
    }

    @include mq($until: desktop) {
      margin-right: 0;
      padding-right: $spacing-16;
    }
  }
  &:first-of-type {
    flex: 1 1 10%;
    min-width: 140px;

    @include mq($until: desktop) {
      padding-left: $spacing-16;
    }
  }
}

.rowTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  @include mq($until: desktop) {
    font-size: 12px;
    line-height: 16px;
  }
}

.columnTitle {
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  line-height: 20px;

  @include mq($until: desktop) {
    font-size: 12px;
    line-height: 16px;
  }
}

.columnTitleSmall {
  composes: columnTitle;
  font-size: 12px;
  line-height: 16px;
}

.textarea {
  min-height: 100px;
  resize: none;
}

.markdownContainer {
  background: $white;
  padding: $spacing-4 $spacing-8;
  min-height: 30px !important;

  & p {
    margin: $spacing-4 0;
  }

  & ul,
  & ol {
    padding-left: $spacing-32;
    margin: $spacing-4 0;
  }
}

.deprecatedContainer {
  composes: markdownContainer;
  background: $grey-2;
}

.markdownContainerCell {
  composes: markdownContainer;

  min-height: 90px !important;
}

.plainValueWrapper {
  flex: 1 1 auto;
  min-height: 40px;

  & > div {
    border: 1px solid $grey-3;
    border-radius: 4px;
    height: 100%;
  }

  @include mq($until: desktop) {
    display: flex;
    flex-direction: column;

    & > div {
      flex-grow: 1;
    }
  }
}

.supportPeriods {
  composes: editorFlexContainerNarrow;
  width: 80%;
  min-width: 700px;
  margin-top: $spacing-16;

  @include mq($until: desktop) {
    min-width: 0px;
  }
}

.supportPeriod {
  background: white;
  border: 1px solid $grey-3;
  border-radius: 4px;
}

.supportPeriodDates {
  padding: $spacing-12;
  border-top: 1px solid $grey-3;
}

.supportPeriodLabel {
  font-weight: 500;
}

.newSupportPeriodDate {
  color: $darkgreen;
}

.deprecatedFieldLabel {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: $darkred;
}

.cancelSupportChange {
  margin-top: $spacing-24;
}

// override styles for react-rte
.editorWrapper {
  flex: 1 1 auto;
  max-width: 100%;
  hyphens: auto;

  // targets editor root
  & > div {
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-3;
    border-radius: 4px;
    font-family: inherit;
    min-height: 120px;

    @include mq($until: desktop) {
      min-height: 90px;
    }

    // targets toolbar
    & > div:nth-of-type(1) {
      margin: 0;
      padding: 5px;
      border-color: $grey-3;
      flex: 0 0 auto;
      display: flex;
      flex-wrap: wrap;

      // targets button groups
      & > div {
        margin-bottom: 0;
        height: 24px;

        &:last-of-type {
          margin-left: auto;
          margin-right: 0;
        }

        // targets button root
        & > div {
          height: 24px;
          z-index: 2;
          // targets button element
          & > button {
            border-color: $grey-3;
            padding: 0;
            height: 24px;
          }
        }
      }
    }

    // targets textarea
    & > div:nth-of-type(2) {
      margin: 0;
      padding: 0;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      border: none;

      & > div {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        & > div:nth-of-type(2) {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;

          & > div {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

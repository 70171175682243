@import "../../../styles/variables";

.searchBox {
  border-radius: 4px;
  border: 1px solid $grey-2;
  background: $grey-2;
  cursor: pointer;
  color: $grey-8;
  flex: 5;

  & svg {
    color: $grey-8;
  }

  &:hover {
    border-color: $grey-3;
    background: $grey-3;
    color: $grey-8;
  }
}

.top {
  display: flex;
  height: 40px;
}

.hasValue {
  background: #fff;
  border-color: $grey-4;
  color: $grey-10;

  & svg {
    color: $grey-7;
  }

  &:hover {
    background: #fff;
    border-color: $grey-6;
    color: $grey-10;
  }
}

.active,
.active:hover {
  background: #fff;
  border-color: $grey-6;
  color: $grey-10;

  & svg {
    color: $grey-9;
  }
}

.textBox {
  flex: 4;
  padding: 0;

  &:hover {
    color: $grey-10;

    & svg {
      color: $grey-9;
    }
  }

  &:focus {
    outline: none;

    & > .textBoxInner {
      color: $grey-10;

      & > svg {
        color: $grey-9;
      }
    }
  }
}

.textBoxInner {
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: $spacing-8;

  &:focus {
    outline: none;
  }
}

.searchIcon {
  flex: 0 0 auto;
  margin-right: $spacing-8;
  width: 20px;
  height: 20px;
}

.textInput {
  padding: 0 !important;
  height: auto !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  &::placeholder {
    opacity: 1;
  }
}

.textInput,
.staticText {
  flex-grow: 1;
  transform: translateY(-1px);
}

.removeSearch {
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    color: $grey-6;
    width: 16px;
    height: 16px;

    &:focus {
      outline: none;
    }
  }

  &:hover > svg {
    color: $grey-9;
  }

  &:focus {
    outline: none;

    & > svg {
      color: $grey-9;
    }
  }
}

.toggleSearchDisplay {
  display: flex;
  width: 100%;
  background: $grey-2;
  border-top: 1px solid $grey-3;
  font-size: 12px;
  color: $grey-8;
  padding: 0;
  border-radius: 0 0 4px 4px;

  &:focus {
    outline: none;

    & > .toggleSearchDisplayInner {
      background: $grey-3;
      color: $grey-10;

      & > svg {
        color: $grey-9;
      }
    }
  }
}

.toggleSearchDisplayInner {
  position: relative;
  flex-grow: 1;
  display: flex;
  padding: $spacing-4;
  border-radius: 0 0 4px 4px;

  &:focus {
    outline: none;
  }

  &:hover {
    background: $grey-3;
    color: $grey-10;

    & > svg {
      color: $grey-9;
    }
  }

  & > span {
    flex-grow: 1;
    text-align: center;
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    color: $grey-6;
  }
}

@import "../../../styles/variables";
@import "~sass-mq";

.container {
  position: relative;
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  @include mq($until: desktop) {
    padding-bottom: 24px + 24px + 40px;
    //Button height and spacing around it
  }

  background-color: $grey-2;
}

.header {
  padding: $spacing-8;

  border-bottom: 1px solid $grey-3;

  background-color: $white;
  @include mq($from: desktop) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.footer {
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  @include mq($until: desktop) {
    position: fixed;
    bottom: $tab-bar-height + 24px;
    right: 24px;
  }
  @include mq($from: desktop) {
    position: sticky;
    bottom: 0;
    padding: $spacing-16;
  }
  &.native {
    @include mq($until: desktop) {
      bottom: 24px;
    }
    @include mq($from: desktop) {
      bottom: 0;
    }
  }
}

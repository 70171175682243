@import "../../../../shared/styles/variables.scss";
@import "~sass-mq";

.navigationBar {
  display: none;
  position: sticky;
  top: 0;
  height: $navigation-bar-height;
  z-index: 10;

  box-shadow: 0 0 48px 0 rgba($darkblue, 0.075), 0 0 8px 0 rgba($darkblue, 0.075);

  background-color: $white;
  @include mq($until: desktop) {
    display: flex;
    flex-shrink: 0;
  }
}

.left {
  width: $navigation-bar-height;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: $spacing-16;
  padding-right: $spacing-16;

  overflow: hidden;
}
.title {
  overflow: hidden;

  text-overflow: ellipsis;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $darkblue;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}
.right {
  width: $navigation-bar-height;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.icon {
  fill: $darkblue;
  height: $spacing-32;
  width: $spacing-32;
}

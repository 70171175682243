@import "../../../styles/variables";
@import "~sass-mq";

.container {
  display: flex;
  flex-shrink: 0;

  background-color: #fff;

  text-decoration: none;

  &.unread {
    background-color: lighten($mintgreen, 8%);
    &:hover {
      background-color: lighten($mintgreen, 6%);
    }
  }
  &.new {
    background-color: lighten($powder, 2%);
    &:hover:not(.active) {
      background-color: lighten($powder, 1%);
    }
  }
  &.active {
    background-color: lighten($darkblue, 65%);
  }
  &:hover:not(.active):not(.unread):not(.new) {
    background-color: $grey-1;
  }
}

.wrapper {
  &.expanded {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.07), 0 0 16px rgba(0, 0, 0, 0.07);
  }
}

.left {
  position: relative;
  flex-shrink: 0;
  padding: $spacing-16;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq($until: tablet) {
    padding: 12px;
    padding-right: 8px;
  }
  .container.expanded & {
    border-bottom: 1px solid $grey-3;
  }
}

.right {
  flex-grow: 1;
  display: flex;
  min-height: 100px;
  flex-direction: column;
  justify-content: center;
  padding: $spacing-16 $spacing-16 $spacing-16 0;
  overflow: hidden;

  border-bottom: 1px solid $grey-3;
  @include mq($until: tablet) {
    padding: 12px 12px 12px 0;
  }
}

.expandCollapse {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 80px;
  flex-shrink: 0;

  border-left: 1px solid $grey-3;
  border-bottom: 1px solid $grey-3;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $grey-2;
  }
  .container.active & {
    border-left-color: lighten($darkblue, 60%);
    &:hover {
      background-color: lighten($darkblue, 63%);
    }
  }
  .container.unread & {
    &:hover {
      background-color: lighten($mintgreen, 4%);
    }
  }
}
.expandCollapseInner {
  position: relative;
  text-align: center;
  margin-top: 8px;
}
.expandCollapseCount,
.expandCollapseShow {
  position: absolute;
  top: -10px;
  left: 50%;

  color: $darkblue;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;

  transform: translateX(-50%);
}
.expandCollapseShow {
  top: auto;
  bottom: -4px;
  font-weight: 700;
}
.expandCollapseIcon {
  width: 32px;
  height: 32px;

  fill: $darkblue;

  transition: transform 120ms ease;
  .expanded & {
    transform: rotate(180deg);
  }
}

.top {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}

.topLeft {
  overflow: hidden;
  padding-right: $spacing-8;
}

.topRight {
  display: flex;
  align-items: flex-start;
}

.title {
  font-size: 16px;
  line-height: 24px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .unread & {
    color: $grey-10;
    font-weight: 500;
  }
}

.from,
.readCount {
  font-size: 14px;
  line-height: 20px;

  color: $grey-8;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  .unread & {
    color: $grey-10;
    font-weight: 500;
  }
  .new & {
    color: $grey-10;
  }
}

.newLabel {
  font-weight: 500;
  font-size: 14px;
  color: $red;
}

.receiverIcon {
  position: relative;
  top: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 4px;
  line-height: 20px;

  fill: $grey-7;
}

.date,
.readCount {
  margin-right: $spacing-2;

  color: $grey-8;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  .unread & {
    color: $grey-10;
    font-weight: 400;
  }
  .new & {
    color: $grey-10;
  }
}

.readCounts {
  position: relative;
  flex-shrink: 0;
  overflow: visible;
}

.readCount {
  position: relative;
  display: inline-block;
  margin-right: $spacing-8;

  &:not(:first-child) {
    padding-left: $spacing-8;

    &::before {
      content: "";
      position: absolute;
      background: $grey-6;
      width: 1px;
      height: 10px;
      left: 0;
      top: calc(50% + 1px);
      transform: translateY(-50%);
    }
  }

  & > svg {
    margin-right: $spacing-4;
    transform: translateY(2px);
  }
}

.readCountTooltip {
  position: absolute;
  z-index: 5;
  top: -2px;
  left: 3px;
  transform: translateY(-100%);
  white-space: normal;
  width: 160px;
  font-size: 12px;
  line-height: 1.2;
  background: #fff;
  padding: $spacing-4 $spacing-8;
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.3);

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.2s 0.2s ease-in-out;

  .readCount:hover & {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(calc(-100% - 3px));
  }
}

.bottom {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}
.subtitle {
  font-size: 14px;
  line-height: 20px;
  color: $grey-8;
  .unread & {
    color: $grey-10;
    font-weight: 400;
  }
  .new & {
    color: $grey-10;
  }
}

.options {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 88px;
  left: 14px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  animation: dropdown 0.1s ease-in-out backwards;

  @include mq($until: tablet) {
    top: 84px;
    left: 10px;
  }
}

.optionsButton {
  position: absolute;
  top: 59px;
  padding: 0;
  width: 32px;
  height: 24px;

  @include mq($until: tablet) {
    top: 55px;
  }

  &:focus {
    outline: none;
    & > .optionsButtonInner {
      box-shadow: inset 0 0 0 1px $grey-8;
    }
  }
}

.optionsButtonInner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 18px;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    fill: $grey-8;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: inset 0 0 0 1px $grey-6;
    & > svg {
      fill: $grey-10;
    }
  }
}

.optionsButtonActive {
  box-shadow: inset 0 0 0 1px $grey-6;

  & > svg {
    fill: $grey-10;
  }
}

.pinButton,
.archiveButton {
  display: flex;
  padding: $spacing-8 $spacing-12;
  padding-right: $spacing-24;
  border-top: 1px solid $grey-3;
  color: $grey-9;
  z-index: 120;
  &:first-of-type {
    border-top: none;
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }

  &:hover,
  &:focus {
    background: $grey-1;
    color: $darkblue;
  }

  &:focus {
    outline: none;
  }

  &.pinned {
    color: $orange;
    z-index: 120;
  }

  & > span {
    flex-shrink: 0;
    white-space: nowrap;
  }

  & > svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: $spacing-12;
    transform: translateY(2px);
  }
}

.childWrapper {
  margin-left: 24px;
  border-left: 1px solid $grey-3;
}

.iconContainer {
  width: 40px;
  height: 40px;
}
.icon {
  display: block;
  margin: 0 auto;
  width: 32px;
  height: 32px;

  fill: $darkblue;
}

.pinnedIcon {
  flex-shrink: 0;
  fill: $orange;
  margin-left: 5px;
}

.iconSmall {
  composes: icon;
  width: 28px;
  height: 28px;
}

.logo {
  width: 32px;
  height: 32px;
}

.marketing,
.internal {
  border: 1px solid;
  border-radius: 20px;
  padding: 0 $spacing-4 2px $spacing-4;
  margin-left: $spacing-8;
  font-size: 12px;
  line-height: 1.5;
}

.marketing {
  color: $darkgreen;
}

.internal {
  color: $darkblue;
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@import "~sass-mq";
@import "../../../shared/styles/variables.scss";

.infoContainer{
  background: rgb(250, 251, 253);
  border-radius: 103px 103px 0px 0px;
  width: 100%;
  position: relative;
  z-index: 5;
  top: 68px;
  padding: 30px 50px 0px 50px;
  overflow-x: hidden;

}
.descriptionText{
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 25px 10px 0px;
}
.topInfoItem{
  flex: 1;
  display: flex;
  border-bottom: 1px solid rgb(235, 238, 245);;
}
.infoItem{
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid rgb(235, 238, 245);
}
.groupedContainer{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgb(235, 238, 245);
}
.infoItemGrouped{
  flex: 1;
  display: flex;
  flex-direction: row;
}
.infoItemGroupedBorder{
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(235, 238, 245);;
}
.border{
  border: 1px solid $grey-3;
}
.borderLeft{
  border-left: 1px solid $grey-3;
}
.grayText{
  color: rgb(89, 89, 89);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  margin: 10px 0px 10px 15px;
}

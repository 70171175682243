@charset "utf-8";
@viewport {
  width: device-width;
}

/*
  Resets annoying things */

* {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border-radius: 0;
}

html {
  text-decoration-skip-ink: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
}

strong,
b {
  font-weight: bold;
}

em,
i {
  font-style: italic;
}

/*
  Misc element fixes */

a {
  background-color: transparent;
}

img {
  max-width: 100%;
}

/*
  Buttons and forms */

button,
[type="button"],
[type="submit"],
[type="reset"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  white-space: normal;
}

::-webkit-input-placeholder {
  opacity: 0.618;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.618;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.618;
  color: inherit;
}

::placeholder {
  opacity: 0.618;
  color: inherit;
}

:disabled {
  opacity: 0.618;
  cursor: not-allowed;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  resize: vertical;
  display: block;
}

/*
  Removes touch input lag from tappable things */

a,
button,
input,
textarea,
select,
label,
canvas {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

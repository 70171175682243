@import "../../../styles/variables";
@import "~sass-mq";

.container {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: hidden;

  background: #fff;
}

.content {
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
}

.contentInner {
  position: relative;
  max-width: 640px;
  padding: $spacing-32 $spacing-16;
  margin: 0 auto;
  @include mq($until: desktop) {
    padding-top: 60px + $spacing-16;
    padding-bottom: $spacing-64 + $spacing-32;
  }
}

.header {
  display: flex;
  z-index: 5;

  border-bottom: 1px solid $grey-3;

  background: white;

  @include mq($until: desktop) {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 $spacing-8;
    align-items: flex-start;
    &.headerCollapsed {
      height: 60px;
      overflow: hidden;
    }
  }
  @include mq($from: desktop) {
    position: sticky;
    align-items: center;
    top: 0;
    padding: $spacing-8;
    width: 100%;
    min-height: 60px;
  }
  &.headerWeb {
    @include mq($until: desktop) {
      top: $navigation-bar-height;
    }
  }
  > * + * {
    margin-left: $spacing-8;
  }
}

.headerAvatar {
  flex-shrink: 0;
  @include mq($until: desktop) {
    display: flex;
    align-items: center;
    height: 60px;
  }
}
.headerContent {
  flex-grow: 1;
  overflow: hidden;
  @include mq($until: desktop) {
    padding: $spacing-8 0;
    .headerCollapsed & {
      padding: $spacing-4 0 0;
      display: flex;
      align-items: center;
      height: 60px;

      white-space: nowrap;
    }
  }
  @include mq($from: desktop) {
    display: flex;
    align-items: center;
  }
}
.headerContentText {
  flex-grow: 1;
  overflow: hidden;
}

.headerButtons {
  display: flex;
  align-items: center;

  @include mq($until: desktop) {
    height: 60px;
  }
}

.headerButtonOuter {
  position: relative;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0 $spacing-8;

  &:focus {
    outline: none;

    & > .headerButtonInner {
      outline: 2px solid $lightblue;
    }
  }
}

.headerButtonInner {
  width: 100%;
  height: 100%;

  & > svg {
    fill: $grey-8;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    & > svg {
      fill: $grey-10;
    }
  }
}

.pinned {
  & > svg {
    fill: $orange;
  }

  &:hover > svg {
    fill: lighten($orange, 5%);
  }
}

.archived {
  & > svg {
    fill: $darkblue;
  }

  &:hover > svg {
    fill: lighten($darkblue, 5%);
  }
}

.headerContentArchive {
  @include mq($until: desktop) {
    margin-top: $spacing-8;
    .headerCollapsed & {
      display: none;
    }
  }
  @include mq($from: desktop) {
    margin-left: $spacing-8;
  }
}

.headerFrom {
  overflow: hidden;

  font-size: 12px;
  line-height: 16px;
  color: $grey-8;
}

.headerToWithCutoff {
  composes: headerFrom;

  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    white-space: normal;
  }
}

.headerNames {
  color: $grey-10;
}

.headerTitle {
  overflow: hidden;

  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
}

.headerExpand {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0;
  &:focus {
    outline: none;
  }
  @include mq($from: desktop) {
    display: none;
  }
}

.headerExpandIcon {
  width: 32px;
  height: 32px;
  fill: $grey-6;
  transition: transform 0.2s ease-in-out;
  .headerCollapsed & {
    transform: rotate(180deg);
  }
  .headerExpand:focus & {
    fill: $red;
  }
}

.SendIcon {
  color: $red;
}

.footer {
  z-index: 5;

  padding: 0 $spacing-16 $spacing-16;

  background-color: white;
  &.footerWeb {
    @include mq($until: desktop) {
      bottom: $tab-bar-height;
    }
  }
  @include mq($until: desktop) {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
  @include mq($from: desktop) {
    position: sticky;
    bottom: 0;
  }
}

.icon {
  display: block;
  width: 32px;
  height: 32px;

  fill: $darkblue;
}
.iconSmall {
  composes: icon;
  width: 28px;
  height: 28px;
}

.logo {
  width: 32px;
  height: 32px;
}

.lockedContainer {
  display: flex;
  padding: 8px;

  border-radius: 8px;

  background-color: $grey-3;
}
.lockedIcon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  fill: $grey-8;
}
.lockedText {
  color: $grey-8;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.marketing,
.internal {
  border: 1px solid;
  border-radius: 20px;
  padding: 0 $spacing-4;
  margin-right: $spacing-4;
  font-size: 10px;
}

.marketing {
  color: $darkgreen;
}

.internal {
  color: $darkblue;
}

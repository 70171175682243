@import "../../../styles/variables";

.container {
  position: relative;
  display: flex;
  align-items: stretch;

  border: 1px solid $grey-3;

  border-radius: $spacing-8;

  &.hasError {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $darkred;
  }
}

.left {
  display: flex;
  align-items: flex-end;
  border-right: 1px solid $grey-3;
}

.textarea {
  width: 100%;
  padding: $spacing-12;
  font-size: 16px;
  line-height: 24px;

  resize: none;

  border: none;
  background-color: transparent;
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 2px solid $white;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: $white;
  }
}

.right {
  display: flex;
  align-items: flex-end;
}

.iconButton {
  display: block;
  padding: $spacing-12 $spacing-8;

  cursor: pointer;
}

.icon {
  display: block;
  width: 24px;
  height: 24px;
  fill: $grey-7;
  .iconButton:hover & {
    fill: $red;
  }
}

.submitButton {
  display: block;
  padding: $spacing-12 $spacing-8;
  &:disabled {
    opacity: 1;
  }
}

.submitButtonIcon {
  composes: icon;
  fill: $red;

  .submitButton:disabled & {
    fill: $grey-5;
  }
}

.errorContainer {
  padding: $spacing-4 $spacing-8;
  border-top-left-radius: $spacing-8;
  border-top-right-radius: $spacing-8;

  color: $white;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  background-color: $darkred;
}

.attachmentInput {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: -100;
}

.attachmentSelector {
  display: flex;
  align-items: center;

  .attachmentInput:focus ~ & {
    background: $grey-2;
  }
}

.attachmentInfo {
  position: absolute;
  top: -35px;
  display: flex;
  align-items: center;
}

.attachmentName {
  padding-right: $spacing-8;
  min-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $grey-8;

  & > span {
    border-left: 1px solid $grey-4;
    margin: 0 $spacing-8;
  }
}

.attachmentRemove {
  display: flex;
  align-items: center;
  border-radius: 50%;

  & > svg {
    color: $grey-8;
  }

  &:focus {
    outline: none;
    background: $grey-2;
  }
}

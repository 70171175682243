@import "../../styles/variables";

.text {
  padding: 0 $spacing-24;
  margin-bottom: $spacing-8;

  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $darkblue;
  font-size: 16px;
  line-height: 20px;
}

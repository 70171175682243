@import "../../../styles/variables.scss";
@import "~sass-mq";

.month {
  display: flex;
}
.monthName {
  flex-grow: 1;
  padding-top: $spacing-12;
  padding-bottom: $spacing-12;
  padding-left: $spacing-16;
  padding-right: $spacing-16;

  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  @include mq($from: tablet) {
    padding-top: $spacing-16;
    padding-bottom: $spacing-16;
    padding-left: $spacing-24;
  }
}
.monthNavigation {
  display: flex;
}
.monthNavigationButton {
  display: block;
  padding: 0 8px;

  border-left: 1px solid $grey-3;
  &:hover {
    background-color: $grey-1;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $darkblue inset;
  }
  &:first-child {
    padding-right: 12px;
  }
  &:last-child {
    padding-left: 12px;
  }
}
.monthNavigationIcon {
  display: block;
  width: 32px;
  height: 32px;

  color: $darkblue;
}

.usedTime {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.usedTimeExceeded {
  color: $darkred;

  & > svg {
    margin-right: $spacing-8;
  }
}

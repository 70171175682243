//
//  Flexbox
.flex,
.flex-x {
  display: flex;
}

.flex-y {
  display: flex;
  flex-direction: column;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.even > * {
  flex-basis: 0;
  flex-grow: 1;
}

.grow,
.grow-children > * {
  flex-basis: auto;
  flex-grow: 1;
}

.no-shrink {
  flex-shrink: 0;
}

.wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

@import "../../../shared/styles/variables.scss";
@import "~sass-mq";

.container {
  position: relative;
  display: flex;
}

.dayPickerContainer {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1px;
  width: 360px;
  max-width: 100vw;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), -4px 4px 16px rgba(0, 0, 0, 0.05);
  z-index: 100;
  background: $white;
}

.button {
  display: flex;
  align-items: center;
  padding: 16px;

  border-left: 1px solid $grey-3;

  color: $darkblue;
  font-weight: 500;

  &:hover {
    background-color: $grey-1;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 16px rgba($darkblue, 0.25) inset;
  }
}

.dayPickerContainer {
  :global {
    // WARNING:
    // This file has been copied from /shared/components/DayPicker

    .DayPicker {
      margin-left: auto;
      margin-right: auto;
      color: $darkblue;
      touch-action: none;
      // overscroll-behavior: contain;
      // overflow: auto;
      // -webkit-overflow-scrolling: touch;
      // scroll-snap-type: y proximity;
      // height: 100%;
    }

    .DayPicker *:focus {
      outline: none;
    }

    .DayPicker,
    .DayPicker * {
      user-select: none;
    }

    .DayPicker-wrapper {
      position: relative;
      flex-direction: row;
    }

    .DayPicker-Months {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 16px;

      .DayPicker--hasWeekNumbers & {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .DayPicker-Month {
      display: table;
      border-spacing: 0;
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;
      // scroll-snap-align: center;
    }

    .DayPicker-Navbar {
      display: flex;
      right: 0;
      top: 0;
      position: absolute;
      height: 64px;
      visibility: hidden;
    }

    .DayPicker-NavButton {
      cursor: pointer;
      display: flex;
      align-items: center;
      visibility: visible;
      padding: 0 8px;

      border-left: 1px solid $grey-3;

      &:first-child {
        padding-right: 12px;
      }
      &:last-child {
        padding-left: 12px;
      }
      &:hover {
        background-color: $grey-1;
      }
      &:focus {
        box-shadow: 0 0 0 1px $darkblue inset;
      }
      svg {
        width: 32px;
        height: 32px;

        fill: $darkblue;
      }
    }

    .DayPicker-NavButton:hover,
    .DayPicker-NavButton:focus {
      text-decoration: underline;
    }

    .DayPicker-NavButton--interactionDisabled {
      display: none;
    }

    .DayPicker-Caption {
      display: table-caption;
      padding: 16px 24px;
      padding-right: 110px;
      margin-bottom: 16px;
      margin-left: -16px;
      margin-right: -16px;

      border-bottom: 1px solid $grey-3;

      color: $grey-10;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      text-transform: capitalize;

      .DayPicker--hasWeekNumbers & {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
      }
    }

    .DayPicker-CaptionButton {
      pointer-events: all;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0;
      visibility: visible;
    }

    .DayPicker-CaptionButton:hover,
    .DayPicker-CaptionButton:focus {
      text-decoration: underline;
    }

    .DayPicker-Weekdays {
      display: table-header-group;
    }

    .DayPicker-WeekdaysRow {
      display: table-row;
    }

    .DayPicker-Weekday {
      display: table-cell;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      color: $grey-10;
      @include mq($from: tablet) {
        font-size: 14px;
      }

      .DayPicker--hasWeekNumbers & {
        padding-top: $spacing-16;

        &:first-of-type {
          width: 4em;
          border-right: 1px solid $grey-3;
        }
      }
    }

    .DayPicker-Weekday abbr[title] {
      border-bottom: none;
      text-decoration: none;
    }

    .DayPicker-Body {
      display: table-row-group;
    }

    .DayPicker-Week {
      display: table-row;
    }

    .DayPicker-Day {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      position: relative;
      font-weight: 700;
      font-size: 14px;
      touch-action: none;
      cursor: pointer;
      @include mq($from: tablet) {
        font-size: 18px;
      }
    }

    .DayPicker-Day * {
      pointer-events: none;
    }

    .DayPicker-WeekNumber {
      display: table-cell;
      min-width: 1em;
      vertical-align: middle;
      text-align: center;
      font-weight: 700;
      font-size: 12px;
      color: $grey-10;
      border-right: 1px solid $grey-3;

      @include mq($from: tablet) {
        font-size: 14px;
      }
    }

    .DayPicker--interactionDisabled .DayPicker-Day {
      cursor: default;
    }

    .DayPicker-Footer {
    }

    .DayPicker-TodayButton {
      border: none;
      background-color: transparent;
      background-image: none;
      box-shadow: none;
      cursor: pointer;
    }

    /* DayPickerInput */

    .DayPickerInput {
      display: inline-block;
    }

    .DayPickerInput-OverlayWrapper {
      position: relative;
    }

    .DayPickerInput-Overlay {
      position: absolute;
      left: 0;
      z-index: 1;

      background: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }

    // Modifiers

    .DayPicker-Day--disabled {
      font-weight: 400;
      color: transparentize($darkblue, 0.5);
    }

    .DayPicker-Day--today .DayPicker-DayPositioner {
      font-weight: bold;
      color: $white;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 18%;
        bottom: 18%;
        left: 18%;
        right: 18%;
        z-index: -1;

        border-radius: 100px;

        background: $darkgreen;
      }
      @include mq($from: tablet) {
        &:after {
          top: 20%;
          bottom: 20%;
          left: 20%;
          right: 20%;
        }
      }
    }

    .DayPicker-Day--disabled .DayPicker-DayBackground {
      opacity: 0.618;
    }

    .DayPicker-Day--reserved {
      color: $white;
    }

    .DayPicker-Day--selecting {
      background-color: $grey-3;
    }
    .DayPicker-Day--adding {
      background-color: lighten($lightblue, 30%);
    }
    .DayPicker-Day--deleting {
      background-color: lighten($darkred, 42%);
    }

    .DayPicker-Day--outside {
    }

    // Custom inner day wrappers to make days square

    .DayPicker-DaySquarer {
      padding-top: 100%;
      width: 100%;
      pointer-events: none;
    }

    // and to add some padding around the ranges

    .DayPicker-DayPositioner {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      z-index: 0;

      .DayPicker-Day:focus-visible & {
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 75%;
          height: 75%;
          transform: translate(-50%, -50%);
          z-index: -1;

          border-radius: 50%;

          background-color: rgba(0, 0, 0, 0.146);
          border: 1px solid white;
        }
      }
    }

    // Outlines

    .DayPicker-DayOutline {
      position: absolute;
      left: 1px;
      top: 1px;
      right: 1px;
      bottom: 1px;
      border: 3px solid transparent;
      pointer-events: none;
    }

    .DayPicker-Day--highlight:not(.DayPicker-Day--selected) .DayPicker-DayOutline {
      border-color: $orange;
      border-width: 3px;
      left: 0;
      right: 0;
      border-radius: 50%;
    }

    .DayPicker-Day--selected .DayPicker-DayOutline {
      border-color: $orange;
      border-width: 3px 0;
      left: 0;
      right: 0;
    }

    .DayPicker-Day--firstOfSelectedRange .DayPicker-DayOutline,
    .DayPicker-Day--selected:first-child .DayPicker-DayOutline {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      left: 1px;
      border-left-width: 3px;
    }

    .DayPicker-Day--lastOfSelectedRange .DayPicker-DayOutline,
    .DayPicker-Day--selected:last-child .DayPicker-DayOutline {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      right: 1px;
      border-right-width: 3px;
    }

    // Reserved styling and range connections

    .DayPicker-DayBackground {
      position: absolute;
      left: 10%;
      top: 10%;
      right: 10%;
      bottom: 10%;
      border-radius: 50%;
      pointer-events: none;
    }

    .DayPicker-Day--reserved .DayPicker-DayBackground {
      border-radius: 0;
      left: 0;
      right: 0;
      border-width: 3px 0;
    }

    .DayPicker-Day--firstOfReservedRange .DayPicker-DayBackground,
    .DayPicker-Day--reserved:first-child .DayPicker-DayBackground,
    .DayPicker--hasWeekNumbers .DayPicker-Day--reserved:nth-child(2) .DayPicker-DayBackground {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      left: 10%;
      border-left-width: 3px;
    }

    .DayPicker-Day--lastOfReservedRange .DayPicker-DayBackground,
    .DayPicker-Day--reserved:last-child .DayPicker-DayBackground {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      right: 10%;
      border-right-width: 3px;
    }

    // Markers under days

    .DayPicker-Markers {
      position: absolute;
      display: flex;
    }

    .DayPicker-Markers--bottom {
      left: 0;
      right: 0;
      top: 50%;
      margin-top: 20%;
      justify-content: center;
      color: $orange;
    }

    .DayPicker-Markers--topRight {
      right: 25%;
      bottom: 50%;
      margin-bottom: 10px;
      color: $red;
    }
    .DayPicker-Markers--topLeft {
      left: 25%;
      bottom: 35%;
      margin-bottom: 10px;
      @include mq($from: tablet) {
        left: 30%;
        bottom: 45%;
      }
    }

    .DayPicker-Marker {
      width: 6px;
      height: 6px;
      border-radius: 50%;

      box-shadow: 0 0 0 2px $white;

      background-color: currentcolor;
      @include mq($from: tablet) {
        width: 8px;
        height: 8px;
      }
    }
    .DayPicker-LetterMarker {
      text-transform: uppercase;
      font-size: 10px;
      line-height: 10px;
      font-weight: 700;
      @include mq($from: tablet) {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .DayPicker-Marker + .DayPicker-Marker {
      margin-left: 2px;
    }

    .DayPicker-Legend {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding: 12px 0;

      border-top: 1px solid $grey-3;
      @include mq($until: tablet) {
        padding: 4px 0;
      }
    }
    .DayPicker-LegendItem {
      display: flex;
      align-items: center;
      padding: 4px 16px;

      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      &:before {
        content: "";
        display: block;
        margin-right: 8px;

        width: 8px;
        height: 8px;

        border-radius: 50%;
      }
      &.DayPicker-LegendItem--holiday:before {
        background-color: $red;
      }
      &.DayPicker-LegendItem--vacationPeriod:before {
        background-color: $orange;
      }
      &.DayPicker-LegendItem--text {
        &:before {
          display: none;
        }
      }
      @include mq($until: tablet) {
        padding: 2px 4px;
      }
    }

    .DayPicker-LegendItem-textMarker {
      margin-right: 8px;

      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;

      color: transparentize($darkblue, 0.5);
    }
  }
}

@import "../../styles/variables";
@import "~sass-mq";

.container {
  width: 100%;
  z-index: 6;
  position: relative;
  position: -webkit-sticky;
  position: sticky;

  will-change: transform;
}

.stickToTop {
  top: 0;
  animation: arriveFromTop 100ms ease-in;
}

@keyframes arriveFromTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.stickToBottom {
  bottom: 0;
  animation: arriveFromBottom 100ms ease-in;
}

@keyframes arriveFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

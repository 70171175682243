@import "../../styles/variables";
@import "~sass-mq";

.root {
  padding-right: $spacing-12;

  @include mq($from: tablet) {
    padding-right: $spacing-16;
  }
}

.guardian {
  padding: $spacing-16;
}

.topContent {
  display: flex;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: $spacing-8;
}

.guardianTitle {
  composes: title;
  margin-bottom: $spacing-16;
}

.draftLink {
  display: inline-block;
  width: 90px;
  text-align: center;
  padding: $spacing-8 0;
  border: 1px solid $darkblue;
  border-radius: 256px;
  color: $darkblue;
  font-weight: 500;
  text-decoration: none;
}

.publishedLink {
  composes: draftLink;
  color: $white;
  border-color: $red;
  background: $red;
}

.meta {
  margin-top: $spacing-12;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.5;
}

.guardianMeta {
  margin-bottom: $spacing-24;
}

.guardianLink {
  text-decoration: none;
}

.metaItem {
  display: flex;
}

.metaLabelShort {
  width: 90px;
}

.metaLabelLong {
  width: 150px;

  .guardianMeta & {
    width: 100px;
  }
}

.metaValue {
  font-weight: 500;
}

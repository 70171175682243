.Overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 15;

  background-color: rgba(0, 0, 0, 0.3);
}

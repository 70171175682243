@import "../../../styles/variables.scss";
@import "~sass-mq";

.root {
  position: relative;
  padding: 3px 0;
}

.options {
  position: absolute;
  z-index: 70;
  top: 50px;
  right: 0px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.2);
  animation: dropdown 0.1s ease-in-out backwards;
}

.pinButton {
  display: flex;
  padding: $spacing-8 $spacing-12;
  border-top: 1px solid $grey-3;
  color: $grey-9;

  &:first-of-type {
    border-top: none;
    border-radius: 5px 5px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 5px 5px;
  }

  &:hover,
  &:focus {
    background: $grey-1;
    color: $darkblue;
  }

  &:focus {
    outline: none;
  }

  &.pinned {
    color: $orange;
  }

  & > span {
    flex-shrink: 0;
    white-space: nowrap;
  }

  & > svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: $spacing-12;
    transform: translateY(2px);
  }
}

@keyframes dropdown {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@import "../../../styles/variables";

.resultContainer {
  position: relative;
  margin-bottom: $spacing-8;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.result {
  display: block;
  background: #fff;
  border: 1px solid $grey-3;
  border-radius: 5px;
  text-decoration: none;

  code {
    font-family: inherit;
    background: lighten($yellow, 20%);
  }

  &:hover,
  &:focus {
    outline: none;
    border-color: $grey-5;
  }
}

.collapsible {
  border-radius: 5px 5px 0 0;
}

.content {
  display: flex;
  padding: $spacing-12;
}

.avatar {
  flex: 0 0 40px;
}

.groupIcon {
  display: flex;
  justify-content: center;
  width: 40px;
  height: 40px;

  & > svg {
    width: 32px;
    height: 32px;
    color: $darkblue;
  }
}

.threadData {
  flex: 1 1 calc(100% - 40px);
  padding-left: $spacing-12;
}

.authorAndDate {
  display: flex;

  & > span:not(:first-of-type) {
    margin-left: $spacing-8;
  }
}

.author {
  flex: 1 1 auto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.date {
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  color: $grey-8;
}

.resultHeader {
  display: flex;
  padding: $spacing-8 $spacing-12;
  padding-left: 0;
  border-bottom: 1px solid $grey-3;
  font-size: 14px;

  & > span {
    line-height: 1.4;
  }

  & > .subjectLabel {
    font-weight: 500;
  }
}

.subjectLabel {
  text-align: center;
  min-width: 64px;
  white-space: nowrap;
}

.excerpt {
  font-size: 14px;
  line-height: 1.5;
  padding-top: $spacing-4;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.threadSubject {
  font-weight: 500;
  line-height: 1.4;
  padding-top: $spacing-4;
}

.logo {
  margin: 5px;
  width: 30px;
  height: 30px;
}

.children {
  padding: 0 $spacing-16;
}

.collapseButton {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid $grey-3;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background: #fff;
  padding: $spacing-4 $spacing-8;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: $grey-8;

  &:hover,
  &:focus {
    outline: none;
    border-top: 1px solid $grey-5;
    border-color: $grey-5;
    transform: translateY(-1px);
    margin-bottom: -1px;
  }

  & > svg {
    position: absolute;
    top: 50%;
    right: 7px;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
  }
}

.loaderAndErrorContainer {
  background: #fff;
  border: 1px solid $grey-3;
  border-top: none;
}

.threadWithReplies {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid $grey-3;
  border-top: none;
  background: #fff;
  padding: $spacing-8 $spacing-32 $spacing-8 $spacing-12;
  text-decoration: none;
  font-size: 14px;
  color: $grey-9;

  & > svg {
    position: absolute;
    top: 50%;
    right: 7px;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
    color: $grey-8;
  }

  &:hover,
  &:focus {
    outline: none;
    border-top: 1px solid $grey-5;
    border-color: $grey-5;
    transform: translateY(-1px);
    margin-bottom: -1px;

    & > svg {
      color: $black;
    }
  }
}

.threadWithRepliesContent {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.threadWithRepliesAuthor {
  font-weight: 500;
  color: $black;
  margin-right: $spacing-4;

  code {
    font-family: inherit;
    background: lighten($yellow, 20%);
  }
}

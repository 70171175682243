@import "../../styles/variables";
@import "~sass-mq";

$gutter: $spacing-4;
$max-width: 256px;

.ThumbnailGrid {
  flex-shrink: 0;
  margin-top: $spacing-8;
  overflow: hidden;
}
.Grid {
  display: flex;

  flex-wrap: wrap;
  flex-direction: row;
  margin: $gutter / 2 * -1;
}

.Heading {
  padding: $spacing-8 $spacing-16;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.Box {
  position: relative;
  width: 100%;
  width: calc(100% / 4 - #{$gutter});
  margin: $gutter / 2;
  @include mq($from: tablet) {
    width: calc(100% / 5 - #{$gutter});
  }
  @include mq($from: desktop) {
    width: calc(100% / 5 - #{$gutter});
  }
  @include mq($from: wide) {
    width: calc(100% / 6 - #{$gutter});
    max-width: $max-width;
  }
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
}

@import "../../styles/variables";

$aside-animation-time: 200ms;
$aside-animation-easing: ease;

.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 20;

  // box-shadow: 0 0 128px 0 rgba($darkblue, 0.075);
  border: 1px solid $grey-3;
  background-color: $white;
}
.open {
  composes: sidebar;
  transform: translateX(0);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $sidebar-width/2;
  padding: $spacing-16;
  flex-shrink: 0;
}

.top {
  margin-bottom: $spacing-24;
}

.main {
  flex-shrink: 0;
  flex-grow: 1;
}
.mainTop {
  margin-bottom: $spacing-24;
}
.mainBottom {
  margin-bottom: $spacing-24;
}

.bottom {
  flex-shrink: 0;
}

@import "../../styles/variables";

.sidebarButton {
  position: relative;
  display: flex;
  align-items: center;
  padding: $spacing-16 $spacing-24;

  text-decoration: none;

  cursor: pointer;
  &:hover {
    background-color: $grey-2;
  }
}

.active {
  background-color: $grey-2;
}

.icon {
  display: block;
  height: $spacing-24;
  width: $spacing-24;
  margin-right: $spacing-12;

  color: $darkblue;
  svg {
    height: $spacing-24;
    width: $spacing-24;
  }
  .active & {
    color: $red;
  }
}

.label {
  flex-grow: 1;

  color: $darkblue;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  .active &,
  .sidebarSubButtonActive & {
    color: $red;
  }
}

.dropdownContainer {
  display: block;
}
.dropdownButton {
  composes: sidebarButton;
}
.dropdownButtonOpen {
  &:hover {
  }
}
.dropdownChevron {
  fill: $darkblue;

  .dropdownButtonOpen & {
    fill: $red;

    transform: rotate(90deg);
  }
}

.dropdownLinks {
}

.sidebarSubButton {
  composes: sidebarButton;
  padding: $spacing-8 $spacing-24;
  padding-left: $spacing-24 + $spacing-24 + $spacing-12;
  &.active {
    background-color: none;
  }
}

.sidebarSubButtonActive {
  background-color: none;
}

.badge {
  position: absolute;
  top: 6px;
  left: 15px;
  background: $red;
  color: $white;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  border: 2px solid $white;

  .active &,
  .sidebarButton:hover & {
    border-color: $grey-2;
  }
}

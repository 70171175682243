@import "../../styles/variables.scss";
@import "~sass-mq";

.inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 auto;
  width: 100%;
  @include mq($from: tablet) {
    max-width: 600px;
  }
}

.outer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @include mq($from: tablet) {
    padding-left: 48px;
    padding-right: 48px;
  }

  &.lessPadding {
    @include mq($from: tablet) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

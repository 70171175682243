@import "../../styles/variables.scss";

/* The Toggle - the box around the Slider */
.toggle {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
}

/* Hide default HTML checkbox */
.input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 25px;

  background-color: $grey-5;
  transition: background-color 0.4s ease;
}

.slider:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  top: 2px;
  left: 2px;

  border-radius: 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);

  background-color: white;
  transition: left 0.4s ease;
}

.input:checked + .slider {
  background-color: $darkgreen;
}

.input:focus + .slider {
  box-shadow: 0 0 1px $darkgreen;
}

.input:checked + .slider:before {
  left: 48px - 24px + 2px;
}

@import "../../../../../shared/styles/variables.scss";
@import "~sass-mq";

.container {
  margin-bottom: $spacing-32;

  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  color: $grey-10;

  background-color: $yellow;
  @include mq($from: tablet) {
    margin-top: $spacing-32;
    border-radius: $spacing-8;
  }
  > * + * {
    margin-left: $spacing-8;
  }
}

.staffContainer {
  composes: container;
  margin-top: 0;
  margin-bottom: 0;
}

.header {
  display: flex;
  padding: $spacing-8 $spacing-16;

  background-color: darken($yellow, 10%);
  @include mq($from: tablet) {
    border-top-left-radius: $spacing-8;
    border-top-right-radius: $spacing-8;
  }
}
.title {
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  margin-right: 8px;

  fill: $grey-10;
}

.toggle {
  align-self: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.content {
  padding: $spacing-16;
}

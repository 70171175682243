@import "../../styles/variables";

.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  background-color: $grey-1;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  min-height: 320px;
  padding: $spacing-24;
  margin-left: auto;
  margin-right: auto;

  border: 1px solid $grey-3;
  border-radius: 8px;

  text-align: center;

  background-color: $white;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 4px;
}

.paragraph {
  font-size: 16px;
  line-height: 20px;
}

.logo {
  display: block;
  width: 80px;
  height: 80px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: $spacing-24;
}

// Main brand color
$red: #ff5847;
$darkred: #e63c29;
$mintgreen: #d2f3e0;

// Logo color
$darkblue: #143c8c;

// Additional colors
$orange: #ffa249;
$lightblue: #69aadd;
$yellow: #ffd34b;
$lightred: #ffaf97;
$powder: #fdefe7;
$darkgreen: #009a76;
$white: #ffffff;
$black: #000000;

//Greys
$grey-1: #fafbfd;
$grey-2: #f5f6fa;
$grey-3: #ebeef5;
$grey-4: #d1d5e0;
$grey-5: #bec6d4;
$grey-6: #a8b0bf;
$grey-7: #8690a6;
$grey-8: #5f6a82;
$grey-9: #36415c;
$grey-10: #061540;

// Calendar colors
$calendar-border-color-vertical: $grey-3;
$calendar-border-color-horizontal: $grey-2;
$calendar-row-bg-odd: $grey-2;
$calendar-row-bg-even: #fff;
$calendar-row-heading-bg: #fff;

// Sizes
$max-width: 800px;
$sidebar-width: 280px;
$navigation-bar-height: 56px;
$tab-bar-height: 56px;

$calendar-row-height: 72px;
$calendar-row-heading-height: 48px;

// Spacing
$spacing-2: 2px;
$spacing-4: 4px;
$spacing-8: 8px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-32: 32px;
$spacing-48: 48px;
$spacing-64: 64px;
$spacing-128: 128px;

// Using this mixin https://github.com/sass-mq/sass-mq

// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
  mobile: 0px,
  tablet: 640px,
  largeTablet: 768px,
  desktop: 992px,
  notebook: 1200px,
  wide: 1300px,
  superwide: 1600px,
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width. For example: (mobile, tablet, desktop).

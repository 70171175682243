@import "~sass-mq";
@import "../../../styles/variables.scss";


p{
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}

.eventListItemContainer{
  position: relative;
  padding-bottom: 20px;
  padding-top: 20px;
  min-height: 130px;
  padding-left: 20px;
  /*
  &.customer{
    margin: 10px;
    border-radius: 8px;
    background-color: rgb(244, 250, 247);
    &:hover{
    background-color: rgb(244, 252, 255);
    }
  }
  */
  &.staff{
    border-bottom: 1px solid $grey-3;
    background-color: white;
    &:hover{
      background-color: rgb(244, 250, 247);
    }
  }
  &.important{

    background-color: rgb(247, 228, 211);
    &:hover{
      background-color: rgb(239, 203, 167);
    }
  }
  &.dashboard{
    margin: 5px 0px 5px 0px;
    border: 1px solid $grey-3;
    background-color: white;
    min-height: 0px;
  }
  &.selectedItem{
    background-color: rgb(199, 214, 207);
    &:hover{
    background-color: rgb(199, 214, 207);
    }
  }
  &.importantSelected{
    background-color: rgb(239, 203, 167);
    &:hover{
    background-color: rgb(239, 203, 167);
    }
  }

  cursor: pointer
}
.eventListItemPadding{

  &.staff{
    //border-bottom: 1px solid $grey-3;

  }
  /*
  &.customer{
    padding-bottom: 10px;
  }
  */

  &.dashboard{
    padding-top: 5px;
    padding-bottom: 0px;
  }
  display: block;
}
.eventDate{
  letter-spacing: 0px;
  font-size: 14px;
  font-family: Maax;
  color: rgb(95, 106, 130);
  display: inline-block;
}
.eventTitle{
  color: rgb(6, 21, 64);
  font-family: Maax;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  margin-top: 0px;
}
.eventTime{
  color: rgb(95, 106, 130);
  font-size: 14px;
  letter-spacing: 0px;
  &.customer{
    margin-top: 0px;
  }
}
.eventSubtitles{
  color: rgb(6, 21, 64);
  font-family: Maax;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
}
.eventDescription{
  font-family: Maax;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 5px;
  &.dashboard{
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.eventImportant{
  display: inline-block;
  float: right
}
.starIcon{
  background-color: #ff6524;
  color: white;
  position: absolute;
  border-radius: 25px;
  height: 15px;
  width: 15px;
  left: 5px;
  top: 5px;
}
.arrowRightIcon{
  position: absolute;
  right: 5px;
  height: 25px;
  width: 25px;
  top: 40%;
  color: #143c8c;
}
.RSVPstatus{
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.RSVPreplyDeadLineText{
  color: $darkblue;
  font-size: 14px;
  letter-spacing: -0.24px;
  line-height: 20px;
  margin-top: 5px;
}
.receiverTag{
  background-color: rgb(235, 238, 245);
  border-radius: 5px;
  color: rgb(20, 60, 140);
  font-family: Maax;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  display: inline-block;
  padding: 4px 10px 4px 10px
}

.dot{
  position: relative;
  bottom: 3px;
  margin-left: 3px;
  margin-right: 3px
}

.attendingContainer{
  border: 1px solid rgb(235, 238, 245);
  display: flex;
  padding:10px;
  border-radius: 8px;
  background-color: rgb(250, 251, 253);
  cursor: pointer;
  &.attending{
    background-color: rgb(244, 252, 247)
  }
}
.attendingLeftText{
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  flex: 2;
  color: rgb(134, 144, 164);
  &.attending{
    color: green
  }
}

.attendingRightText{
  font-size: 16px;
  position: absolute;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgb(134, 144, 164);
  text-align: right;
  &.attending{
    color: blue
  }
}

.checkCircle{
  margin-right: 5px;
  background-color: green;
  color: white;
  border-radius: 28px;
}
.color{
  color: red;
}
.description{
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(95, 106, 130);
}

@import "../../styles/variables";

.listItemNotifier {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: $grey-8;

  & > svg {
    flex: 0 0 auto;
    width: 16px;
    height: 16px;
    margin-right: $spacing-8;
  }
}

.childSearch {
  padding: $spacing-8;
  border-bottom: 1px solid $grey-3;
  align-items: flex-start;

  & > svg {
    transform: translateY(1px);
  }
}

@import "~sass-mq";
@import "../../../shared/styles/variables.scss";

.form {
  padding: 20px 80px 50px 80px;
}
.border {
  border: 1px solid $grey-3;
}
.listTitle {
  align-items: center;
  padding: 0 $spacing-12;
  margin-bottom: $spacing-8;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  color: $grey-8;
  @include mq($from: tablet) {
    padding: 0 $spacing-16;
  }
}
.bottomContainer {
  display: flex;
  justify-content: flex-end;
}
.timePickerText {
  color: rgb(95, 106, 130);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  width: 46px;
  margin-left: 20px;
  white-space: nowrap;
  padding: 12px 00px 12px 0px;
  flex: 1;
}
.timeInput {
  input[type="time"] {
    text-align: right;
    border: none;
    border-radius: 0;
    min-width: 100%;
    -webkit-appearance: none;
    &::-webkit-input-placeholder {
      line-height: normal !important;
    }
    &:focus {
      outline: none;

      border: none;
      box-shadow: 0 0.25rem 1rem -0.25rem rgba($darkblue, 0.4) inset, 0 0 0 1px $darkblue inset;
    }
  }
}

.timeWrapper {
  display: flex;
  align-items: stretch;
  cursor: pointer;
  &:hover {
    background: $grey-1;
  }
}
.root input[type="text"] {
  border-radius: 0px;
  margin-top: 0px;
}
.marginBorderWrapper {
  margin-top: 30px;
  border: 1px solid #ebeef5;
}
.addClockTimeButton {
  display: inline-block;
  background-color: rgb(235, 238, 245);
  border-radius: 5px;
  color: rgb(95, 106, 130);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  padding: 5px 5px 5px 5px;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
  margin-left: 20px;
}
.removeClockTimeButton {
  display: inline-block;
  position: relative;
  bottom: 90px;
  right: 15px;
  padding-left: 7px;
  padding-top: 0px;
  cursor: pointer;
}
.dateText {
  margin-right: 80px;
  margin-left: 60px;
}
.table {
  border-collapse: collapse;
  border: 1px solid $grey-3;
  display: inline-block;
}
.table td {
  padding-top: 0px;
  border-bottom: 1px solid $grey-3;
}

.table tr {
  background-color: white;
}

.tableCellBorderLeft {
  border-left: 1px solid $grey-3;
}
.timePickerWrapper {
  width: 60%;
  background-color: white;
  border: 1px solid $grey-3;
}
.isImportantWrapper {
  border: 1px solid $grey-3;
  margin-top: 40px;
}
.timePickerStartContainer {
  display: flex;
  border-bottom: 1px solid $grey-3;
}
.timePickerEndContainer {
  display: flex;
}
.clockStartContainer {
  border-left: 1px solid $grey-3;
  flex: 1;
}

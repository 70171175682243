@import "../../styles/variables.scss";
@import "~sass-mq";

// Help icon and title that open inline
.inlineHelp {
  &.list {
    padding: $spacing-8 $spacing-12;
    @include mq($from: tablet) {
      padding: $spacing-8 $spacing-16;
    }
  }
}

// Header used to display small amount of text. If children are present acts as button to collapse children.
.inlineHelpHeader {
  display: inline-flex;
  padding: 0 0 $spacing-4;
  cursor: default;
  &.hasChildren {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
}

.inlineHelpIcon {
  width: 20px;
  height: 20px;
  margin-right: $spacing-8;
  flex-shrink: 0;

  fill: $grey-7;
  .inlineHelpHeader.hasChildren & {
    fill: $darkblue;
  }
  .inlineHelpHeader.hasChildren:hover & {
    fill: $red;
  }
  .inlineHelpHeader.red & {
    fill: $red;
  }
}

.inlineHelpTitle {
  color: $darkblue;
  font-size: 14px;
  line-height: 20px;
  color: $grey-8;
  .inlineHelpHeader.hasChildren & {
    color: $darkblue;
    text-decoration: underline;
  }
  .inlineHelpHeader.hasChildren:hover & {
    color: $red;
  }
  .inlineHelpHeader.hasChildren:focus & {
    text-decoration: underline;
  }
  .inlineHelpHeader.red & {
    color: $red;
    font-weight: 500;
  }
}

.inlineHelpChevron {
  display: none;
  align-self: center;
  width: 16px;
  height: 16px;
  margin-left: $spacing-4;

  fill: $darkblue;

  transition: transform 120ms ease;
  &.flip {
    transform: rotate(180deg);
  }
  &.show {
    display: block;
  }
}

.inlineHelpContent {
  padding-left: 28px;

  font-size: 14px;
  line-height: 20px;
  > * + * {
    margin-top: $spacing-16;
  }
}

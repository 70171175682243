@import "../../../styles/variables";

.root {
  position: sticky;
  top: 60px;
  z-index: 8;

  & > div {
    transition: all 0.2s ease-in-out;
  }
}

.container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 20px);
  height: 40px;
  background: $grey-1;
  color: $grey-9;
  font-weight: 500;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: popIn 0.2s ease-in-out backwards;
}

.text {
  padding-bottom: 2px;
}

.fadeout {
  opacity: 0;
}

.close {
  position: absolute;
  top: 50%;
  right: $spacing-8;
  transform: translateY(-50%);
  padding: 0;
  width: 24px;
  height: 24px;

  &:focus {
    outline: none;

    & > .closeInner {
      outline: 2px solid $lightblue;
    }
  }
}

.closeInner {
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }

  & > svg {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: $grey-6;
  }

  &:hover > svg {
    fill: $grey-9;
  }
}

@keyframes popIn {
  from {
    opacity: 0;
    transform: scale(0.98) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@import "../../../styles/variables";

.container {
  width: 100%;
  max-height: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  background: #fff;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: $spacing-8;
  }
}
.icon {
  svg {
    width: 48px;
    height: 48px;

    fill: $grey-6;
  }
}
.text {
  color: $grey-8;
  font-size: 16px;
  line-height: 24px;
}

@import "~sass-mq";
@import "../../../styles/variables.scss";

.attendingContainer{
  border-left: 1px solid $grey-3;
  border-top: 1px solid $grey-3;
  border-right: 1px solid $grey-3;
  width: 100%;
  margin-top: 30px;
  background-color: white;
}
.attendingSingleItem{
  display: flex;
  border-bottom: 1px solid $grey-3;
}
.parentsAmountText{
  margin-top: 10px;
}
.nameContainer{
  padding: 10px;
  flex: 3
}
.countContainer{
  @include mq($from: mobile) {
    max-width: 120px;
  }
  @include mq($from: desktop) {
    min-width: 170px;
  }
  position: relative;

  padding: 10px;
  border-left: 1px solid $grey-3;
}


.icon{
  flex:10;
  position: relative;
  top: 6px;
  margin-right: 5px;
}
.attendingText{
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
}
.green{
  color: rgb(0, 154, 118);
}

.gray{
  color: rgb(134, 144, 164);
}

.singleItemName{
  margin-top: 8px;
  display: inline-block;
  margin-left: 20px
}

.singleItemAttendingText{
  margin-top: 8px;
  display: inline-block;
  float: right;
  margin-right: 20px
}

.borderLeft{
  border-left: 1px solid $grey-3;
}

.borderBottom{
  border-bottom: 1px solid $grey-3;
}

.doubleParentAttending{
  display: inline-block;
  float: right;
  height: 120px;
  padding: 30px;
  border-left: 1px solid $grey-3;
}

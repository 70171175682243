@import "../../styles/variables.scss";
@import "~sass-mq";

$aside-animation-time: 200ms;
$aside-animation-easing: ease;

@value sideBarWidth 280px;

.wrapper {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.wrapperOpen {
  overflow-x: hidden;
}

.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 75%;
  max-width: sideBarWidth;
  z-index: 20;
  flex-shrink: 0;
  @include mq($until: desktop) {
    transition: transform $aside-animation-time $aside-animation-easing;
    transform: translateX(calc(sideBarWidth * -1));
  }
}

.asideOpen {
  composes: aside;
  transform: translateX(0);
}

.main {
  position: relative;
  flex-grow: 1;
  flex-basis: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  transition: transform $aside-animation-time $aside-animation-easing;
  @include mq($from: desktop) {
    padding-left: sideBarWidth;
  }
}

.inner {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @include mq($until: desktop) {
    overflow-y: auto;
  }
  @include mq($from: desktop) {
    height: 100%;
  }
}

.mainOpen {
  @include mq($until: desktop) {
    transform: translateX(sideBarWidth);
  }
}
.mainOpenPercentages {
  @include mq($until: desktop) {
    transform: translateX(75%);
  }
}

.logo {
  display: block;
  width: $sidebar-width/3;
}

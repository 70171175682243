.react-dropdown-tree-select {
  position: relative;

  .dropdown {
    width: 100%;

    .dropdown-content {
      width: 100%;
      padding: 8px !important;
      z-index: 11 !important;
    }
  }

  .dropdown-trigger {
    width: 100%;
    display: flex !important;
    align-items: center;
    border: none !important;
    padding: 4px 6px !important;
  }

  .tag-list {
    flex-grow: 1;
  }

  .tag-item {
    .search {
      padding: 0 12px !important;
      border-bottom: none !important;
    }
  }

  .infinite-scroll-component {
    max-height: 500px;
  }

  .node {
    display: flex;
    align-items: center;
    padding-top: 6px !important;
    padding-bottom: 6px !important;

    &[aria-level="4"] {
      label {
        margin-left: 24px;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #ebeef5;
    }

    label {
      display: inline-flex;
      align-items: center;
    }

    i {
      font-style: normal;
      font-weight: 500;
      width: 20px;
      text-align: center;

      color: #143c8c;

      &:hover {
        color: #ff5847;
      }
    }
  }

  .node-label {
    margin-left: 8px;
  }
}

@import "../../styles/variables";

.Thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  background-color: $grey-3;
  cursor: pointer;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    border: 0px solid $grey-3;

    pointer-events: none;
    transition: border-width 80ms ease;
  }
  &.checkedThumbnail {
    &:before {
      border-width: 16px;
    }
  }
}
.icon {
  width: auto;
  top: 40%;
  left: 50%;
  height: 50px;
  width: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  color: white;
  opacity: 0.7;
}

.sharedBadge {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 8px;
  left: 50%;
  padding: 0 8px;
  transform: translateX(-50%);

  border: 1px solid $white;
  border-radius: 32px;

  color: $white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  background-color: $darkgreen;
}
.sharedIcon {
  width: 16px;

  fill: $white;
}
.checkedBadge {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 32px;
  height: 32px;
  padding: 4px;

  border: 2px solid $white;

  border-radius: 32px;

  background-color: $darkblue;
}

.checkedIcon {
  width: 100%;
  height: 100%;

  fill: $white;
}

@import "../../../styles/variables.scss";
@import "~sass-mq";

.large {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing-32;

  background-color: $darkblue;
  color: $white;

  border-bottom-left-radius: 64px;
  border-bottom-right-radius: 64px;
}

.compact {
  position: relative;
  display: flex;
  align-items: center;
  padding: $spacing-32 $spacing-16 0;
}

.compact > * + * {
  margin-left: 8px;
}

.name {
  font-weight: 500;
}

.large .name {
  margin-top: 8px;
  font-size: 24px;
  line-height: 32px;
}

.backLink {
  position: absolute;
  left: $spacing-16;
  top: $spacing-16;

  @include mq($from: desktop) {
    display: none;
  }
}

.buttonContainer{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.buttonAddPic{
  margin-right: 10px
}

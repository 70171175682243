.receiverTag{
  background-color: rgb(235, 238, 245);
  border-radius: 5px;
  margin-right: 5px;
  color: rgb(20, 60, 140);
  font-family: Maax;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 20px;
  display: inline-block;
  padding: 4px 10px 4px 10px
}

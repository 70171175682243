@import "~sass-mq";
@import "../../../shared/styles/variables.scss";

.dot{
  position: relative;
  bottom: 3px;
  margin-left: 3px;
  margin-right: 3px
}

.RSVPstatus{
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 5px
}

.guardianText{
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgb(89, 89, 89)
}

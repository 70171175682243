@import "../../styles/variables";
@import "~sass-mq";

$active-bar-width: 3px;

.container {
  position: sticky;
  z-index: 1;
  top: 0;
  height: 60px;
  flex: 0 0 60px;
  display: flex;
  background: white;
  border-bottom: 1px solid $grey-3;
  overflow: auto;
}

.button {
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 $spacing-16;

  border-bottom: $active-bar-width solid transparent;

  text-decoration: none;
  & > svg {
    fill: $grey-7;
    width: 18px;
    height: 18px;
    margin-right: $spacing-8;
  }

  &:hover {
    background: $grey-2;
  }

  &.active {
    border-color: $red;

    & > svg {
      fill: $red;
    }
  }
}

.text {
  font-size: 16px;
  line-height: 20px;
  color: $grey-7;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .active & {
    color: $red;
  }
}

.unreadBadge {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 12px;
  top: 16px;
  background: $darkred;
  border: 2px solid $white;
  border-radius: 50%;
}

.starIcon{
  background-color: #ff6524;
  color: white;
  position: relative;
  border-radius: 25px;
  height: 15px;
  width: 15px;
  margin-left: 5px;
  top: 2px;
  margin-right: 5px;
}

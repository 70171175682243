@import "~sass-mq";
@import "../../../shared/styles/variables.scss";

.border {
  border: 1px solid $grey-3;

}
.rsvpPickerContainer{
  display: inline-block;
  float: right;
  position: absolute;
  right: 20px;
  top: 20px
}

.rsvpTimePicker{
  border: none !important;
  width: auto !important;
  position:relative;
  color: #009a76;
  margin-left: 5px;
  height: 30px;
  margin-top: 3px
}
.orangeText{
  color: rgb(255, 88, 71);
  font-size: 14px;
  letter-spacing: -0.24px;
  line-height: 20px;
  margin-top: 15px;
}
.rsvpDeadline{
  display: inline-flex;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 60px
}
.rsvpDateText{
  color: #009a76;
  display: inline-block;
  font-size: 16px;
  background-color: white;
  height: 30px;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 5px
}

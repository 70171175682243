@import "../../../styles/variables";

.unreadBadge {
  min-width: 20px;
  padding: 0 4px;
  height: 20px;
  margin-top: $spacing-2;

  box-shadow: 0 0 0 2px #fff;

  border-radius: 20px;
  flex-shrink: 0;

  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;

  background-color: $darkgreen;
}

.absolute {
  position: absolute;
  top: 8px;
  left: 8px;
}

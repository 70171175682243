@import "../../styles/variables";

.container {
  padding: 0 $spacing-24;
  > * + * {
    margin-top: $spacing-8;
  }
}

.picker {
  position: relative;
  height: 48px;
  padding: $spacing-16;

  border-radius: $spacing-8;

  background-color: $grey-3;

  cursor: pointer;
}

.select {
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  padding-left: $spacing-16;
  padding-right: $spacing-16;

  color: $darkblue;
  font-weight: 500;

  border-radius: $spacing-8;

  cursor: pointer;

  &:focus {
    outline: none;
  }

  // Disable default styling on ff
  -moz-appearance: none;
  // Disable default styling on webkit browsers
  -webkit-appearance: none;
  // Disable default arrow on IE 11+
  &::-ms-expand {
    display: none;
  }
  // IE 9 only
  @media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    &:after,
    &:before {
      display: none;
    }
  }
  option,
  optgroup {
    padding: 0;
  }
}

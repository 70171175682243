@import "../../../styles/variables";

.container {
  padding: 16px;
}

.button {
  display: block;
  width: 100%;
  height: 48px;

  border-radius: 8px;

  white-space: nowrap;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  color: $white;

  background-color: $darkblue;

  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    background: darken($darkblue, 6%);
  }
}

.search {
  margin-top: $spacing-8;
  background: none;
  color: $darkblue;
  border: 1px solid transparent;
  height: 36px;
  line-height: 17px;

  &:hover,
  &:focus {
    background: none;
    border-color: $grey-4;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}

@import "../../styles/variables";
@import "~sass-mq";

.outer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  &.padding {
    @include mq($from: desktop) {
      padding: $spacing-16;
    }
  }
}

.tabsContainer {
  @include mq($until: desktop) {
    padding: 16px 16px 0;
    border-bottom: 1px solid $grey-3;
    .rightSideActiveOnMobile & {
      display: none;
    }
  }
}

.inner {
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  overflow: hidden;

  background: white;
  .padding & {
    @include mq($from: desktop) {
      border: 1px solid $grey-3;
    }
  }
}

.left {
  flex-shrink: 0;
  overflow-y: auto;

  @include mq($until: desktop) {
    flex-basis: 100%;
    max-width: 100%;
  }
  @include mq($from: desktop) {
    min-width: 340px;
    max-width: 420px;

    border-right: 1px solid $grey-3;
  }
  .rightSideActiveOnMobile & {
    @include mq($until: desktop) {
      display: none;
    }
  }
}

.right {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow-y: auto;

  background-color: $grey-1;

  @include mq($until: desktop) {
    display: none;
  }
  .rightSideActiveOnMobile &,
  .noLeftSide & {
    @include mq($until: desktop) {
      display: flex;
      flex-basis: 100%;
    }
  }
}

.vasu {
  & .left {
    background: $grey-2;

    @include mq($until: notebook) {
      max-width: 100%;

      border-bottom: 1px solid $grey-3;
      border-right: none;
      overflow-y: visible;
    }
    @include mq($from: desktop) {
      max-width: 100%;

      border-bottom: 1px solid $grey-3;
      border-right: none;
    }
    @include mq($from: notebook) {
      min-width: 320px;
      max-width: 320px;

      border-bottom: none;
      border-right: 1px solid $grey-3;
    }
    @include mq($from: wide) {
      min-width: 340px;
      max-width: 340px;
    }

    @media print {
      display: none;
    }
  }

  & .right {
    background: $white;

    @include mq($until: notebook) {
      display: flex;
      flex-basis: 100%;
      overflow-y: visible;
    }
  }

  & .inner {
    @include mq($until: notebook) {
      flex-direction: column;
      max-height: auto;
      overflow-y: auto;
    }
  }
}

.vasuDoc .left {
  @include mq($from: desktop) {
    min-width: 300px;
    min-width: 300px;
  }
}

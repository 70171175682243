@import "../../styles/variables.scss";

.container {
  &.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 100%;
    }
  }
}

.inner {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    margin-top: 8px;
  }
  .large & {
    > * + * {
      margin-top: 16px;
    }
  }
  &.centered {
    &:after {
      // Push loader a bit from center to visually center it better
      content: "";
      display: block;
      padding-top: 100%;
    }
  }
  .inline & {
    display: flex;
    flex-direction: row;
    > * + * {
      margin-top: 0;
      margin-left: 8px;
    }
  }
}

.loader {
  display: block;
  width: 40px;
  height: 40px;

  animation-name: spin;
  animation-duration: 2500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  .large & {
    width: 64px;
    height: 64px;
  }
  .inline & {
    width: 24px;
    height: 24px;
  }
}

.text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  color: $darkblue;
  .large & {
    font-size: 20px;
    line-height: 28px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
